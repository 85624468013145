import React from 'react';
import styled from 'styled-components';
import prizeIndicator from '../../../asset/svg/fortuna/prize-indicator.svg';
import wheelCenter from '../../../asset/svg/fortuna/wheel-dot.svg';

import wheel from '../../../asset/png/fortuna-wheel-spin.png';

import { INITIAL_ROTATION } from '../constant';

interface WheelProps {
  isSpinning: boolean;
  rotation: number;
}
export const Wheel: React.FC<WheelProps> = ({
  isSpinning = false,
  rotation = INITIAL_ROTATION,
}) => {
  return (
    <WheelWrapper className="wheel-element">
      <PrizeIndicator src={prizeIndicator} alt="Prize Indicator" />

      <CenterDot src={wheelCenter} alt="CenterDot" />

      <WheelImage
        src={wheel}
        alt="Wheel Segments"
        style={{
          transition: isSpinning ? 'transform 8s ease-out' : 'none',
          transform: `rotate(${rotation}deg)`,
        }}
      />
    </WheelWrapper>
  );
};

const WheelWrapper = styled.div`
  position: relative;
  width: 350px;
  height: 350px;
  overflow: hidden;
  border-radius: 50%;
  @media screen and (max-width: 404px) {
    width: 324px;
    height: 324px;
  }
  @media screen and (max-width: 369px) {
    width: 292px;
    height: 292px;
  }
`;

const PrizeIndicator = styled.img`
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;

  @media screen and (max-width: 404px) {
    top: 6px;
  }
  @media screen and (max-width: 369px) {
    top: 2px;
  }
`;

const CenterDot = styled.img`
  position: absolute;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const WheelImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;

  @media screen and (max-width: 404px) {
    width: 324px;
    height: 324px;
  }
  @media screen and (max-width: 369px) {
    width: 292px;
    height: 292px;
  }
`;
