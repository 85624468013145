import { USER_EARNING_STATUS } from '../../epic/earning-admin-list/constant';
import {
  USER_ITEM_DATA_RAW_INTER,
  USER_ITEM_DATA_INTER,
  USER_ITEM_LIST_DATA_INTER,
} from './constant';

export const convertUser = (
  user: USER_ITEM_DATA_RAW_INTER,
): USER_ITEM_DATA_INTER => {
  const pendingBalance = user?.earning
    ? user.earning
        .filter((earning) => earning.status === USER_EARNING_STATUS.PENDING)
        .reduce((sum, earning) => sum + Number(earning.amount), 0)
    : 0;

  const totalBalance = user?.earning
    ? user.earning
        .filter((earning) => earning.status === USER_EARNING_STATUS.COMPLETE)
        .reduce((sum, earning) => sum + Number(earning.amount), 0)
    : 0;

  const referalUser = user?.earning
    ? user.earning
        .filter(
          (earning) =>
            earning.isPerson === true &&
            earning.status === USER_EARNING_STATUS.COMPLETE,
        )
        .reduce((sum, e) => sum + Number(e.personAmount || 0), 0)
    : 0;

  return {
    ...user,
    pendingBalance,
    totalBalance,
    referalUser,
  };
};

export const convertUserList = (
  list: USER_ITEM_DATA_RAW_INTER[],
): USER_ITEM_LIST_DATA_INTER => {
  return {
    list: list?.map((user: USER_ITEM_DATA_RAW_INTER) => {
      return convertUser(user);
    }),
    isEmpty: list?.length < 1,
  };
};
