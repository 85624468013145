import React from 'react';
import { SelectElem } from '../../common/select';

import styled from 'styled-components';
import { Spacing } from '../../theme';

import { FORTUNA_PRIZE_LIST } from '../../data/fortuna/constant';
import { customUserOption } from '../../data/user/option';
import { FieldTimeInputElem } from '../../common/field-time-input';

export const Component: React.FC<{
  userList: {
    label: string;
    value: string;
  }[];
  handleSelectUserChange: Function;
  updateState: (key: string, value: string | null | string[]) => void;
}> = ({ handleSelectUserChange, userList, updateState }) => {
  const onChangeUser = (
    _e: any,
    data: {
      label: string;
      value: string;
    },
  ) => {
    updateState('user', data?.value);
  };
  const onChangeTime = (_first: any, second: any) => {
    if (second && second[0]) {
      updateState('startDate', second[0]);
    } else {
      updateState('startDate', '');
    }

    if (second && second.length >= 2 && second[1]) {
      updateState('endDate', second[1]);
    } else {
      updateState('endDate', '');
    }
  };

  return (
    <Container>
      <Double>
        <SelectElem
          isClearable
          dynamic
          filterOption={() => true}
          onInputChange={handleSelectUserChange}
          placeholder="FORTUNA.FILTER.USER"
          options={userList}
          name="user"
          onChange={onChangeUser}
          customComponents={customUserOption}
        />

        <SelectElem
          isClearable
          dynamic
          filterOption={() => true}
          placeholder="FORTUNA.FILTER.PRIZE"
          options={FORTUNA_PRIZE_LIST}
          name="prize"
          onChange={(_e: any, value: any) => {
            if (value) {
              updateState('prize', value?.value);
            } else {
              updateState('prize', '');
            }
          }}
        />
      </Double>
      <FieldTimeInputElem allowClear onChange={onChangeTime} />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-gap: ${Spacing(3)};
  margin-bottom: ${Spacing(2)};
`;

const Double = styled.div`
  margin-top: ${Spacing(1)};
  display: flex;
  gap: ${Spacing(2)};
  width: 100%;
`;
