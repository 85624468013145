import { HttpRequest } from '../../lib/http';
import { FETCH_PARAM_INTER } from '../../lib/util/usePagintationData';
import { API } from './constant';

export const action = async ({
  pageParam,
  pageSize,
}: FETCH_PARAM_INTER): Promise<any> => {
  let params = '?';

  if (pageParam) {
    params += `&page=${pageParam}`;
  }

  if (pageSize) {
    params += `&pageSize=${pageSize}`;
  }

  return HttpRequest({
    method: API.TYPE,
    url: API.URL(params),
  });
};
