import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { ACTION_ERROR_INTER, MODULE_NAME } from './constant';
import { Component } from './component';
import { action } from './action';
import { RATING_LIST_USER_INTER, TOP_USER_BY } from '../../data/user/constant';

import { STORE_INTER, USER_ROLE } from '../../data/auth/constant';
import { useSelector } from 'react-redux';
import { AUTH_MODULE_NAME } from '../../data/auth';

export const Container: React.FC = () => {
  const state: STORE_INTER = useSelector((s: any) => s[AUTH_MODULE_NAME]);
  const isAdmin = state.user?.role === USER_ROLE.ADMIN;

  const [filter, setFilter] = useState({
    type: TOP_USER_BY.INVITED_AMOUNT,
    startDate: '',
    endDate: '',
  });

  const updateFilter = (key: string, value: string | null | string[]) => {
    setFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const preFetch = useQuery({
    queryKey: [MODULE_NAME, filter.type, filter.startDate, filter.endDate],
    queryFn: () =>
      action({
        type: filter.type,
        startDate: filter.startDate,
        endDate: filter.endDate,
      }),
  });

  const isLoading = () => {
    if (preFetch.isLoading || preFetch.isFetching) {
      return true;
    }
  };

  const isSuccess = () => {
    if (preFetch.isSuccess && !preFetch.isFetching) {
      return true;
    }
  };

  const isError = () => {
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const onChangeTime = (_first: any, second: any) => {
    if (second && second[0]) {
      updateFilter('startDate', second[0]);
    } else {
      updateFilter('startDate', '');
    }

    if (second && second.length >= 2 && second[1]) {
      updateFilter('endDate', second[1]);
    } else {
      updateFilter('endDate', '');
    }
  };

  const userList = useMemo(() => {
    const list = preFetch?.data as unknown as RATING_LIST_USER_INTER;
    return list?.list;
  }, [preFetch]);

  const userRatingData = useMemo(() => {
    const data = preFetch?.data as unknown as RATING_LIST_USER_INTER;

    return data?.currentUser;
  }, [preFetch]);

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = preFetch.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const isAccountInfo =
    !!isSuccess() &&
    userRatingData &&
    !userList.some((e) => e?.user?.id === userRatingData?.user?.id);

  return (
    <Component
      isAccountInfo={isAccountInfo}
      isAdmin={isAdmin}
      filter={filter}
      onChangeTime={onChangeTime}
      updateFilter={updateFilter}
      userRating={userRatingData}
      list={userList}
      isLoading={isLoading()}
      isError={isError()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
    />
  );
};
