import { Prize, PRIZES } from '../../data/fortuna/constant';

export const MODULE_NAME = 'FORTUNA_SPIN_WHEEL_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'POST',
  URL: `/fortuna/spin`,
};

export const TOTAL_SEGMENTS = 10;
export const SEGMENTAL_ANGLE = 360 / TOTAL_SEGMENTS;

export const INITIAL_ROTATION = 0;
export const calculateRotation = (prize: Prize) => {
  const baseRotation = 360 * 3;

  const index = prize.cellIndex - 1;
  const rotation = SEGMENTAL_ANGLE * index;
  return -(baseRotation + rotation);
};
