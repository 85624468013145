import { HttpRequest } from '../../lib/http';
import { FETCH_PARAM_INTER } from '../../lib/util/usePagintationData';
import { API } from './constant';

export const action = async ({
  type,
  startDate,
  endDate,
}: FETCH_PARAM_INTER): Promise<any> => {
  let params = '?';

  if (type) {
    params += `type=${type}`;
  }

  if (startDate) {
    params += `&startDate=${startDate}`;
  }

  if (endDate) {
    params += `&endDate=${endDate}`;
  }

  return HttpRequest({
    method: API.GET_LIST.TYPE,
    url: API.GET_LIST.URL(params),
  });
};
