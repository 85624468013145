import React from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { GridElem } from '../../common/grid';
import { Card } from './frame/card';
import { EmptyListElem } from '../../common/empty-list-elem';
import { COLOR_ENUM } from '../../theme/color';
import { SkeletonDataElem } from '../../common/skeleton-data';
import { FORTUNA_ITEM_DATA_RAW_INTER } from './constant';
import { PaginationElem } from '../../common/pagination';
import { Spacing } from '../../theme';

export const Component: React.FC<{
  list?: FORTUNA_ITEM_DATA_RAW_INTER[];
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  count?: number;
  onChangePage: (arg: number) => void;
  page: number;
  pageSize: number;
  pageCount: number;
}> = ({
  list,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  page,
  onChangePage,
  pageSize,
  pageCount,
}) => {
  const isEmpty = list && list.length < 1;

  return (
    <GridElemStyled spacing={6}>
      {isLoading && (
        <>
          <Divider />
          <SkeletonDataStyled />
        </>
      )}
      {isError && <AlertActionElem tid={`ERROR.${errorMessage}`} />}
      <GridStyled>
        {isEmpty && !isLoading && (
          <EmptyListElem
            titleTid="FORTUNA.LIST.IS_EMPTY_TITLE"
            descriptionTid="FORTUNA.LIST.IS_EMPTY_DESCRIPTION"
          />
        )}
        {isSuccess &&
          list &&
          !isEmpty &&
          list.map((item) => (
            <>
              <Divider />
              <React.Fragment key={item.id}>
                <Card data={item} />
              </React.Fragment>
            </>
          ))}
        {isSuccess && !isEmpty && <Divider />}
      </GridStyled>
      {!isEmpty && (
        <PaginationStyled
          pageSize={pageSize}
          defaultPageSize={pageSize}
          current={page}
          onChange={onChangePage}
          total={pageCount}
        />
      )}
    </GridElemStyled>
  );
};

const Divider = styled.div`
  background-color: ${({ theme }) => theme[COLOR_ENUM.DIVIDER]};
  height: 1px;
  width: 100%;
`;

const GridElemStyled = styled(GridElem)`
  margin-top: ${Spacing(1)};
  margin-bottom: ${Spacing(22)};
`;

const PaginationStyled = styled(PaginationElem)`
  position: absolute;
  bottom: ${Spacing(33)};
  left: 50%;
  transform: translateX(-50%);
`;

const GridStyled = styled(GridElem)`
  grid-auto-columns: auto max-content max-content;
  row-gap: ${Spacing(4)};
`;

const SkeletonDataStyled = styled(SkeletonDataElem)`
  margin-top: ${Spacing(6)};
`;
