import { FORM_VALUE_INTER } from './constant';
import { validateAmount } from '../../lib/validation/service';

export const convertUser = (data: any) => {
  if (!data) {
    return;
  }

  const converedData = data?.list.map((e: any) => {
    const convert = {
      label: e.userName,
      userName: e.userName,
      level: e.level,
      referalUser: e.referalUser,
      value: e.id,
    };

    return convert;
  });

  return { list: converedData };
};

export const convertEvent = (data: any) => {
  if (!data) {
    return;
  }

  const converedData = data?.list.map((e: any) => {
    const convert = {
      label: e.title,
      value: e.id,
    };

    return convert;
  });

  return { list: converedData };
};

export const convertCreateData = (payload: FORM_VALUE_INTER) => {
  const userId = String(payload?.user[0].value);
  const eventId = payload?.event[0].value;
  const comment = payload?.comment;
  const amount = payload?.amount;

  const isPerson = payload?.isPerson;
  const isInvited = payload?.isInvited;
  const isFortuna = payload?.isFortuna;

  const personData = payload?.personData;

  let personAmount = payload?.personAmount;
  let invitedAmount = payload?.invitedAmount;
  let fortunaAmount = payload?.fortunaAmount;

  // Перевіряємо поля, якщо  `true`
  if (isPerson) personAmount = validateAmount('personAmount', personAmount);
  if (isInvited) invitedAmount = validateAmount('invitedAmount', invitedAmount);
  if (isFortuna) fortunaAmount = validateAmount('fortunaAmount', fortunaAmount);

  return {
    userId,
    eventId,
    comment,
    amount,
    isPerson,
    isInvited,
    isFortuna,
    personData,
    ...(isPerson && { personAmount }),
    ...(isInvited && { invitedAmount }),
    ...(isFortuna && { fortunaAmount }),
  };
};
