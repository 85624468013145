import { useFortunaBalance } from '../../lib/util/useFortunaBalance';
import { Component } from './component';

export const Container: React.FC<{
  isSecondary?: boolean;
}> = ({ isSecondary = false }) => {
  const { fortunaBalance, isLoading } = useFortunaBalance();

  return (
    <Component
      isSecondary={isSecondary}
      fortunaBalance={Number(fortunaBalance || 0)}
      isLoading={isLoading}
    />
  );
};
