export const convert = (data: any) => ({
  ...data,
  eventId: data.event.value,
  comment: data.comment,
  status: data.status.value,
  amount: Number(data.amount),

  fortunaAmount: Number(data.fortunaAmount),
  personAmount: Number(data.personAmount),
  invitedAmount: Number(data.invitedAmount),

  personData: data?.isPerson ? data.personData : '',
});
