import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { TextElem } from '../../../common/text';
import { Spacing } from '../../../theme';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../../theme/value';
import { SelectElem } from '../../../common/select';
import {
  EARNING_ITEM_DATA_INTER,
  FORM_VALUE_ENUM,
  STATUS_OPTION_LIST,
  USER_EARNING_STATUS,
} from '../constant';
import { FieldTextAreaElem } from '../../../common/field-text-area';
import { FieldTextElem } from '../../../common/field-text';
import { EVENT_ITEM_LIST_DATA_INTER } from '../../event-list/constant';
import { EarningItemUpdateContainer } from '../../earning-item-update';

import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { CheckBoxElem } from '../../../common/checkbox';

export const CardEarning: React.FC<{
  data: EARNING_ITEM_DATA_INTER;
  dataEvent?: EVENT_ITEM_LIST_DATA_INTER;
  isUpdateDisabled?: boolean;
  handleSelectEventChange?: Function;
  isOpen: boolean;
  onClick: () => void;
  handleCloseCard: () => void;
}> = ({
  data,
  dataEvent,
  isUpdateDisabled,
  handleSelectEventChange,
  isOpen,
  onClick,
  handleCloseCard,
}) => {
  const [formData, setFormData] = useState({
    amount: Number(data?.amount) || 0,
    fortunaAmount: Number(data?.fortunaAmount) || 0,
    personAmount: Number(data?.personAmount) || 0,
    invitedAmount: Number(data?.invitedAmount) || 0,

    status: data.status,
    event: data.event,
    comment: data.comment,
    personData: data.personData,
    isPerson: !!data.isPerson,
    isFortuna: !!data.isFortuna,
    isInvited: !!data.isInvited,
  });

  const id = data?.user.telegramId;
  const userName = data?.user.userName;

  const eventOption = dataEvent?.list.map((e) => ({
    label: e.title,
    value: e.id,
  }));

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
  };

  const handleChangeSelect = (name: string, value: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div>
      <Container className="user_container" isOpen={isOpen} onClick={onClick}>
        <UserContainer>
          <InfoContainer>
            <TextElem
              size="semiSmall"
              color="white"
              type="semi-bold"
              tid={`ID ${id || ''}`}
            />
            <TextElem
              color="textSecondary"
              size="small"
              type="medium"
              tid={userName}
            />
          </InfoContainer>
        </UserContainer>

        <BalanceContainer>
          <TextElem
            oneLine
            color="white"
            size="semiSmall"
            type="semi-bold"
            tid={`$ ${data.amount}`}
          />
          <TextElem
            color="textSecondary"
            size="verySmall"
            type="semi-bold"
            tid="EARNING.LIST.DATE"
            tvalue={{ date: data.createDate }}
          />
        </BalanceContainer>

        {!isOpen && (
          <RoleContainer>
            <Chip status={data.status.value as USER_EARNING_STATUS}>
              <TextElem
                color="white"
                size="small"
                type="medium"
                tid={data.status.label}
              />
            </Chip>
          </RoleContainer>
        )}
        {isOpen && (
          <RoleButtonContainer>
            <EarningItemUpdateContainer
              handleCloseCard={handleCloseCard}
              disabled={isUpdateDisabled}
              payload={formData}
              id={data.id}
            />
          </RoleButtonContainer>
        )}
      </Container>
      <>
        {isOpen && (
          <ExpandedContent>
            <Double>
              <FieldTextElem
                icon={'$'}
                disabled={data.status.value === USER_EARNING_STATUS.CANCEL}
                name={FORM_VALUE_ENUM.AMOUNT}
                placeholder="EARNING.CREATE.AMOUNT"
                value={formData.amount}
                onChange={handleInputChange}
                type="number"
              />
              <SelectElem
                name={FORM_VALUE_ENUM.STATUS}
                isDisabled={data.status.value === USER_EARNING_STATUS.CANCEL}
                value={formData.status}
                onChange={handleChangeSelect}
                options={STATUS_OPTION_LIST}
                placeholder="EARNING.CREATE.STATUS"
              />
            </Double>
            <SelectElem
              onInputChange={handleSelectEventChange}
              name={FORM_VALUE_ENUM.EVENT}
              value={formData.event}
              isClearable
              onChange={handleChangeSelect}
              options={eventOption}
              placeholder="EARNING.CREATE.EVENT"
              dynamic
            />
            <FieldTextAreaElem
              name={FORM_VALUE_ENUM.COMMENT}
              placeholder="EARNING.CREATE.COMMENT"
              value={formData.comment}
              onChange={handleInputChange}
            />

            <DoubleContainer>
              <CheckBoxElemStyled
                name={FORM_VALUE_ENUM.IS_FORTUNA}
                onChange={handleInputChange}
                checked={formData.isFortuna}
                textSize="semiSmall"
                tid="EARNING.CREATE.FORTUNA"
              />

              <FieldTextElemStyled
                placeholder="EARNING.CREATE.FORTUNA_AMOUNT"
                name={FORM_VALUE_ENUM.FORTUNA_AMOUNT}
                onChange={handleInputChange}
                value={formData.fortunaAmount}
                type="number"
                defaultValue={1}
                disabled={!formData.isFortuna}
              />
            </DoubleContainer>

            <DoubleContainer>
              <CheckBoxElemStyled
                name={FORM_VALUE_ENUM.IS_INVITED}
                onChange={handleInputChange}
                checked={formData.isInvited}
                textSize="semiSmall"
                tid="EARNING.CREATE.INVITED"
              />

              <FieldTextElemStyled
                placeholder="EARNING.CREATE.INVITED_AMOUNT"
                name={FORM_VALUE_ENUM.INVITED_AMOUNT}
                onChange={handleInputChange}
                value={formData.invitedAmount}
                type="number"
                defaultValue={1}
                disabled={!formData.isInvited}
              />
            </DoubleContainer>

            <DoubleContainer>
              <CheckBoxElemStyled
                checked={formData.isPerson}
                name={FORM_VALUE_ENUM.IS_PERSON}
                onChange={handleInputChange}
                textSize="semiSmall"
                tid="EARNING.CREATE.PERSON"
              />

              <FieldTextElemStyled
                placeholder="EARNING.CREATE.PERSON_AMOUNT"
                name={FORM_VALUE_ENUM.PERSON_AMOUNT}
                onChange={handleInputChange}
                value={formData.personAmount}
                type="number"
                defaultValue={1}
                disabled={!formData.isPerson}
              />
            </DoubleContainer>

            {formData.isPerson && (
              <FieldTextAreaElem
                name={FORM_VALUE_ENUM.PERSON_DATA}
                placeholder="EARNING.CREATE.PERSON_DATA"
                value={formData.personData}
                onChange={handleInputChange}
              />
            )}
          </ExpandedContent>
        )}
      </>
    </div>
  );
};

const Chip = styled.div<{ status: USER_EARNING_STATUS }>`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.TAG]}px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${Spacing(2)};
  min-width: 90px;

  ${({ status }) => {
    if (status === USER_EARNING_STATUS.PENDING) {
      return css`
        background-color: rgba(69, 172, 229, 0.3);

        span {
          color: #45ace5;
        }
      `;
    }

    if (status === USER_EARNING_STATUS.COMPLETE) {
      return css`
        background-color: rgba(54, 178, 134, 0.3);

        span {
          color: #36b286;
        }
      `;
    }

    if (status === USER_EARNING_STATUS.CANCEL) {
      return css`
        background-color: rgba(242, 72, 72, 0.3);

        span {
          color: #f24848;
        }
      `;
    }
  }}
`;

const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
  gap: ${Spacing(1)};
`;

const Double = styled.div`
  display: grid;
  grid-gap: ${Spacing(2)};
  width: 100%;
  grid-template-columns: 1fr 1fr;
`;

const ExpandedContent = styled.div`
  grid-column: span 3;
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: ${Spacing(2)};
  align-items: center;
  transition: height 0.3s ease;
  margin-bottom: 12px;
`;

const RoleContainer = styled.div`
  display: grid;
  justify-self: end;
  min-width: 82px;
  text-align: end;
  align-self: center;
`;

const RoleButtonContainer = styled.div`
  display: grid;
  justify-self: end;

  position: absolute;
  right: 8px;
  max-width: 95px;
  top: 50%;
  transform: translateY(-50%);
`;

const UserContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
`;

const InfoContainer = styled.div`
  display: flex;
  text-align: start;
  flex-direction: column;
  gap: ${Spacing(1)};
`;

const Container = styled.div<{ isOpen: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 120px;
  padding: ${Spacing(5)} 0;
  align-items: center;

  position: relative;

  text-align: end;
  cursor: pointer;
  justify-content: center;
  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]},
    height 0.3s ease;

  &:hover {
    > *:not(${RoleButtonContainer}) {
      opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
    }
  }
`;

const DoubleContainer = styled.div`
  display: grid;
  height: 44px;

  align-items: center;
  width: 100%;
  gap: ${Spacing(1)};
  grid-template-columns: 1fr 1fr;
`;

const CheckBoxElemStyled = styled(CheckBoxElem)`
  height: 44px;
  max-height: 44px;
`;

const FieldTextElemStyled = styled(FieldTextElem)`
  height: 44px;
  max-height: 44px;
`;
