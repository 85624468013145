import React, { useEffect, useMemo, useState } from 'react';
import { FormikValues, useFormik } from 'formik';

import { Component } from './component';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ACTION_RESPONSE_INTER } from '../user-list/constant';
import { convertUser, convertCreateData, convertEvent } from './convert';
import {
  ACTION_ERROR_INTER,
  EARNING_USER_LIST_MODULE_NAME,
  FORM_VALUE_ENUM,
  FORM_VALUE_TYPE,
} from './constant';
import { EVENT_LIST_MODULE_NAME } from '../event-list';
import { ACTION_RESPONSE_INTER as ACTION_RESPONSE_EVENT_INTER } from '../event-list/constant';
import { action as eventAction } from '../event-list/action';
import { EARNING_LIST_MODULE_NAME } from '../earning-admin-list';
import { createEarning } from './action';
import { validation } from '../../lib/validation';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { numberPositive, required } from '../../lib/validation/service';
import { getUserListShort } from '../../data/user/action';
import { USER_LIST_SHORT_MODULE_NAME } from '../../data/user/constant';
import { useFortunaBalance } from '../../lib/util/useFortunaBalance';

export const Container: React.FC<{}> = ({}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectUserValue, setSelectUsertValue] = useState('');
  const [selectEventValue, setSelectEventValue] = useState('');
  const { invalidateFortunaBalance } = useFortunaBalance();
  const preFetchUser = useQuery(USER_LIST_SHORT_MODULE_NAME, () =>
    getUserListShort({ search: selectUserValue }),
  );
  const preFetchEvent = useQuery(EVENT_LIST_MODULE_NAME, () =>
    eventAction({ search: selectEventValue }),
  );

  const handleSelectUserChange = (newValue: any, _actionMeta: any) => {
    setSelectUsertValue(newValue);
  };

  const handleSelectEventChange = (newValue: any, _actionMeta: any) => {
    setSelectEventValue(newValue);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      preFetchUser.refetch();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [selectUserValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      preFetchEvent.refetch();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [selectEventValue]);

  const query = useQueryClient();

  const getData = useMemo(() => {
    const data = preFetchUser.data as unknown as ACTION_RESPONSE_INTER;

    if (data) {
      const convertData = convertUser(data);

      return { ...convertData, isEmpty: data?.list?.length < 1 };
    }
  }, [preFetchUser.data]);

  const getDataEvent = useMemo(() => {
    const data = preFetchEvent.data as unknown as ACTION_RESPONSE_EVENT_INTER;

    if (data) {
      const convertData = convertEvent(data);

      return { ...convertData, isEmpty: data?.list?.length < 1 };
    }
  }, [preFetchEvent.data]);

  const eventOptionList = getDataEvent?.list;
  const userOptionList = getData?.list;

  const onSuccess = () => {
    setIsOpen(false);
    query.invalidateQueries(EARNING_LIST_MODULE_NAME);
    query.invalidateQueries(USER_LIST_SHORT_MODULE_NAME);
    invalidateFortunaBalance();
    formik.resetForm();
  };

  const action = useMutation(
    () => {
      return createEarning(convertCreateData(formik.values));
    },
    {
      onSuccess,
    },
  );

  const config = {
    [FORM_VALUE_ENUM.AMOUNT]: [required],
    [FORM_VALUE_ENUM.COMMENT]: [],
    [FORM_VALUE_ENUM.EVENT]: [required],
    [FORM_VALUE_ENUM.USER]: [required],
    [FORM_VALUE_ENUM.PERSON]: [],
    [FORM_VALUE_ENUM.PERSON_AMOUNT]: [],

    [FORM_VALUE_ENUM.FORTUNA]: [],
    [FORM_VALUE_ENUM.FORTUNA_AMOUNT]: [],

    [FORM_VALUE_ENUM.INVITED]: [],
    [FORM_VALUE_ENUM.INVITED_AMOUNT]: [],

    [FORM_VALUE_ENUM.PERSON_DATA]: [],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues = {
    [FORM_VALUE_ENUM.AMOUNT]: '',
    [FORM_VALUE_ENUM.COMMENT]: '',
    [FORM_VALUE_ENUM.EVENT]: '',
    [FORM_VALUE_ENUM.USER]: '',
    [FORM_VALUE_ENUM.PERSON_DATA]: '',

    [FORM_VALUE_ENUM.PERSON]: false,
    [FORM_VALUE_ENUM.FORTUNA]: false,
    [FORM_VALUE_ENUM.INVITED]: false,

    [FORM_VALUE_ENUM.FORTUNA_AMOUNT]: 1,
    [FORM_VALUE_ENUM.PERSON_AMOUNT]: 1,
    [FORM_VALUE_ENUM.INVITED_AMOUNT]: 1,
  };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    onSubmit: () => {
      return action.mutate();
    },
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) => {
    formik.setFieldValue(name, Array.isArray(e) ? e : [e]);
  };

  const handleButtonClick = () => {
    setIsOpen(true);
  };

  const handleCheckboxChange = (e: any) => {
    formik.setFieldValue(FORM_VALUE_ENUM.FORTUNA, e.target.checked);
  };

  return (
    <Component
      handleSelectUserChange={handleSelectUserChange}
      handleSelectEventChange={handleSelectEventChange}
      handleCheckboxChange={handleCheckboxChange}
      handleButtonClick={handleButtonClick}
      isOpen={isOpen}
      setFieldValue={setFieldValue}
      isFieldError={isFieldError}
      getFieldValue={getFieldValue}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      isError={isError()}
      eventList={eventOptionList}
      userList={userOptionList}
      formik={formik}
    />
  );
};
