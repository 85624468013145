import React from 'react';
import styled from 'styled-components';
import { TextElem } from '../../../common/text';
import { Spacing } from '../../../theme';

import { FORTUNA_ITEM_DATA_RAW_INTER } from '../constant';
import { FORTUNA_PRIZE_ENUM } from '../../../data/fortuna/constant';

import moneyGold from '../../../asset/svg/fortuna/money-gold.svg';
import miniSpinGold from '../../../asset/svg/fortuna/spin-again-gold-mini.svg';

import iphone from '../../../asset/png/fortuna-iphone.png';
import airpods from '../../../asset/png/fortuna-airpods-big.png';

export const Card: React.FC<{
  data: FORTUNA_ITEM_DATA_RAW_INTER;
}> = ({ data }) => {
  const withoutDollar = [
    FORTUNA_PRIZE_ENUM.IPHONE_16_PRO,
    FORTUNA_PRIZE_ENUM.AIRPODS_PRO,
    FORTUNA_PRIZE_ENUM.NOTHING,
    FORTUNA_PRIZE_ENUM.REPEAT_SPIN,
  ].includes(data.prize);

  const isAirpods = data.prize === FORTUNA_PRIZE_ENUM.AIRPODS_PRO;
  const isPhone = data.prize === FORTUNA_PRIZE_ENUM.IPHONE_16_PRO;
  const isSpinAgain = data.prize === FORTUNA_PRIZE_ENUM.REPEAT_SPIN;

  const imgSrc = isAirpods
    ? airpods
    : isPhone
    ? iphone
    : isSpinAgain
    ? miniSpinGold
    : moneyGold;
  return (
    <>
      <Container>
        <Block>
          <PrizeImg src={imgSrc} />
          <TextElem
            color="default"
            type="semi-bold"
            size="semiSmall"
            tid={withoutDollar ? data.prize : `$${data.prize}`}
          />
        </Block>

        <DateBlock>
          <TextElem
            color="textSecondary"
            type="medium"
            size="small"
            tid={data.createDate}
          />
        </DateBlock>
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: ${Spacing(1)};
  align-items: center;
  justify-content: space-between;
`;

const Block = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spacing(2.5)};

  overflow: hidden;
  min-width: 140px;
  max-width: 140px;
`;

const DateBlock = styled(Block)`
  min-width: 70px;
  max-width: 70px;
`;

const PrizeImg = styled.img`
  width: 30px;
  height: 30px;
`;
