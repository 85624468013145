import React from 'react';

import styled, { css } from 'styled-components';
import { Wheel } from './frame/wheel';
import { ButtonElem } from '../../common/button';
import { GridElem } from '../../common/grid';
import { TextElem } from '../../common/text';
import { Spacing } from '../../theme';
import { ModalPrize } from './frame/modal-prize';
import { Prize } from '../../data/fortuna/constant';
import { getLocalizedAttempts } from '../../lib/util/getLocalizedAttempts';

interface ComponentProps {
  onSpin: () => void;
  balance: number;
  isSpinning: boolean;
  rotation: number;
  prize: Prize | null;
  onCloseModal: () => void;
}

export const Component: React.FC<ComponentProps> = ({
  onSpin,
  balance,
  isSpinning,
  rotation,
  prize,
  onCloseModal,
}) => {
  const localizedBalance = getLocalizedAttempts(balance);
  const isBalanceZero = balance <= 0;

  return (
    <Container spacing={6}>
      <Wheel isSpinning={isSpinning} rotation={rotation} />
      <GridElemStyled spacing={3}>
        <Grid>
          <ChipStyled isZero={isBalanceZero}>
            <TextElem
              size="semiSmall"
              type="semi-bold"
              color={isBalanceZero ? 'error' : 'default'}
              oneLine
              tid={localizedBalance}
            />
          </ChipStyled>
          <ButtonElem
            type="primary"
            onClick={onSpin}
            disabled={isSpinning || isBalanceZero}
            tid="Крутити колесо"
          />
        </Grid>
      </GridElemStyled>

      {prize && <ModalPrize prize={prize} onClose={onCloseModal} />}
    </Container>
  );
};

const Container = styled(GridElem)`
  margin-top: ${Spacing(6)};
  justify-content: center;
`;

const GridElemStyled = styled(GridElem)`
  justify-content: center;
  align-items: center;
`;

const Grid = styled.div`
  width: 225px;
  text-align: -webkit-center;
`;

const ChipStyled = styled.div<{ isZero: boolean }>`
  border-radius: 4px;
  height: 38px;
  width: 110px;
  padding: ${Spacing(3)} ${Spacing(5)};
  background: linear-gradient(90deg, #f4e1871a, #e5b45f1a);
  margin-bottom: ${Spacing(3)};

  ${({ isZero, theme }) =>
    isZero &&
    css`
      background: #f248481a;
    `}
`;
