import RatingImg from '../../asset/svg/common/rating.svg';
import RatingActiveImg from '../../asset/svg/common/rating-active.svg';

import CalendarImg from '../../asset/svg/common/calendar.svg';
import TeacherImg from '../../asset/svg/common/teacher.svg';
import WalletImg from '../../asset/svg/common/empty-wallet.svg';
import ActivityImg from '../../asset/svg/common/activity.svg';

import CalendarActiveImg from '../../asset/svg/common/calendar-active.svg';
import TeacherActiveImg from '../../asset/svg/common/teacher-active.svg';
import WalletActiveImg from '../../asset/svg/common/empty-wallet-active.svg';
import ActivityActiveImg from '../../asset/svg/common/activity-active.svg';

import { ANALYTIC_PAGE_PATH } from '../../page/analytic';
import { EVENT_PAGE_PATH } from '../../page/event';
import { BALANCE_PAGE_PATH } from '../../page/balance';
import { EDUCATION_PAGE_PATH } from '../../page/education';
import { ACTIVITY_PAGE_PAGE_PATH } from '../../page/activity';
import { USER_ADMIN_PAGE_PATH } from '../../page/admin-user';
import { ADDITIONAL_PAGE_PATH } from '../../page/additional';
import { FAQ_PAGE_PATH } from '../../page/faq';
import { EARNING_ADMIN_PAGE_PATH } from '../../page/admin-earning';
import { WITHDRAW_PAGE_PATH } from '../../page/admin-withdraw';
import { EVENT_ADMIN_PAGE_PATH } from '../../page/admin-event';
import { CREATE_WITHDRAW_GIFT_PAGE_PATH } from '../../page/create-withdraw-gift';
import { CREATE_WITHDRAW_WALLET_PAGE_PATH } from '../../page/create-withdraw-wallet';
import { PROFILE_UPDATE_PAGE_PATH } from '../../page/profile-update';
import { LEVEL_GIFT_PAGE_PATH } from '../../page/level-gift';
import { RATING_LIST_PAGE_PATH } from '../../page/rating-list';
import { FORTUNA_SPIN_WHEEL_PAGE_PATH } from '../../page/fortuna-spin-wheel';
import { FORTUNA_ADMIN_PAGE_PATH } from '../../page/admin-fortuna';
import { FORTUNA_PAGE_PATH } from '../../page/fortuna';

export const ADMIN_PAGE = [
  EARNING_ADMIN_PAGE_PATH,
  USER_ADMIN_PAGE_PATH,
  EVENT_ADMIN_PAGE_PATH,
  ADDITIONAL_PAGE_PATH,
  WITHDRAW_PAGE_PATH,
  FORTUNA_ADMIN_PAGE_PATH,
];

export const WITHOUT_TOOLBAR_PAGE = [
  PROFILE_UPDATE_PAGE_PATH,
  FORTUNA_SPIN_WHEEL_PAGE_PATH,
  LEVEL_GIFT_PAGE_PATH,
  CREATE_WITHDRAW_WALLET_PAGE_PATH,
  CREATE_WITHDRAW_GIFT_PAGE_PATH,
  FAQ_PAGE_PATH,
  FORTUNA_PAGE_PATH,
];

export const ALLOWED_FORTUNA_BALANCE_PAGES = [
  EVENT_PAGE_PATH,
  BALANCE_PAGE_PATH,
];

export const MODULE_NAME = 'MENU_MODULE_NAME';
export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const data: any = {
  user: [
    {
      img: CalendarImg,
      imgActive: CalendarActiveImg,
      tid: 'NAVIGATION.TAB_APP.EVENT',
      path: EVENT_PAGE_PATH,
    },
    {
      img: ActivityImg,
      imgActive: ActivityActiveImg,
      tid: 'NAVIGATION.TAB_APP.ANALYTIC',
      path: ANALYTIC_PAGE_PATH,
    },

    {
      img: WalletImg,
      imgActive: WalletActiveImg,
      tid: 'NAVIGATION.TAB_APP.BALANCE',
      path: BALANCE_PAGE_PATH,
    },
    {
      img: TeacherImg,
      imgActive: TeacherActiveImg,
      tid: 'NAVIGATION.TAB_APP.TEACHING',
      path: EDUCATION_PAGE_PATH,
    },
    {
      img: RatingImg,
      imgActive: RatingActiveImg,
      tid: 'NAVIGATION.TAB_APP.RATING',
      path: RATING_LIST_PAGE_PATH,
    },
  ],
  admin: [],
};

export interface ACTION_RESPONSE_INTER {}

export interface DATA_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}
