import React, { useEffect, useMemo, useState } from 'react';

import { Component } from './component';
import { getUserListShort } from '../../data/user/action';
import { USER_LIST_SHORT_MODULE_NAME } from '../../data/user/constant';
import { useQuery, useQueryClient } from 'react-query';
import { ACTION_RESPONSE_INTER } from '../user-list/constant';
import { convertUser } from '../earning-create/convert';

export const Container: React.FC<{
  updateState: (key: string, value: string | null | string[]) => void;
}> = ({ updateState }) => {
  const [selectUserValue, setSelectUsertValue] = useState('');

  const preFetchUser = useQuery(USER_LIST_SHORT_MODULE_NAME, () =>
    getUserListShort({ search: selectUserValue }),
  );

  const handleSelectUserChange = (newValue: any, _actionMeta: any) => {
    setSelectUsertValue(newValue);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      preFetchUser.refetch();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [selectUserValue]);

  const queryClient = useQueryClient();
  const getData = useMemo(() => {
    const data = preFetchUser.data as unknown as ACTION_RESPONSE_INTER;

    if (data) {
      const convertData = convertUser(data);

      return { ...convertData, isEmpty: data?.list?.length < 1 };
    }

    return undefined;
  }, [preFetchUser.data]);

  const userOptionList = getData?.list;

  return (
    <Component
      updateState={updateState}
      userList={userOptionList}
      handleSelectUserChange={handleSelectUserChange}
    />
  );
};
