import { HttpRequest } from '../../lib/http';
import { FETCH_PARAM_INTER } from '../../lib/util/usePagintationData';
import { API } from './constant';

export const action = async ({
  userId,
  prize,
  startDate,
  endDate,
  pageParam,
  pageSize,
}: FETCH_PARAM_INTER): Promise<any> => {
  let params = '?';

  if (userId) {
    params += `&userId=${userId}`;
  }

  if (prize) {
    params += `&prize=${prize}`;
  }

  if (startDate) {
    params += `&startDate=${startDate}`;
  }

  if (endDate) {
    params += `&endDate=${endDate}`;
  }

  if (pageParam) {
    params += `&page=${pageParam}`;
  }

  if (pageSize) {
    params += `&pageSize=${pageSize}`;
  }

  return HttpRequest({
    method: API.TYPE,
    url: API.URL(params),
  });
};
