import i18next from 'i18next';
import {
  TOP_USER_BY,
  TOP_USER_BY_VIEW,
  USER_ITEM_LIST_DATA_RAW_INTER,
} from '../../data/user/constant';

export const MODULE_NAME = 'RATING_LIST_MODULE_NAME';
export const RATING_PROFILE_MODULE_NAME = 'RATING_PROFILE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  GET_LIST: {
    TYPE: 'GET',
    URL: (params: string) => `/user/top/${params}`,
  },
  // GET_DATA: {
  //   TYPE: 'GET',
  //   URL: (params: string) => `/user/rating-data${params}`,
  // },
};

export interface RatingFilters {
  type: number;
  startDate: string | null;
  endDate: string | null;
}

export const TOP_USER_BY_TAB = [
  {
    tid: TOP_USER_BY_VIEW[TOP_USER_BY.INVITED_AMOUNT],
    value: TOP_USER_BY.INVITED_AMOUNT,
  },
  {
    tid: TOP_USER_BY_VIEW[TOP_USER_BY.SELLS_AMOUNT],
    value: TOP_USER_BY.SELLS_AMOUNT,
  },
  {
    tid: TOP_USER_BY_VIEW[TOP_USER_BY.SELLS_SUM],
    value: TOP_USER_BY.SELLS_SUM,
  },
];



export interface ACTION_RESPONSE_INTER extends USER_ITEM_LIST_DATA_RAW_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}
