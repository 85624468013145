import { ACTIVITY_PAGE_PAGE_PATH } from '../../page/activity';
import { ANALYTIC_PAGE_PATH } from '../../page/analytic';
import { BALANCE_PAGE_PATH } from '../../page/balance';
import { EDUCATION_PAGE_PATH } from '../../page/education';
import { EVENT_PAGE_PATH } from '../../page/event';
import { USER_ADMIN_PAGE_PATH } from '../../page/admin-user';
import { ADDITIONAL_PAGE_PATH } from '../../page/additional';
import { FAQ_PAGE_PATH } from '../../page/faq';
import { WITHDRAW_PAGE_PATH } from '../../page/admin-withdraw';
import { EVENT_ADMIN_PAGE_PATH } from '../../page/admin-event';
import { EARNING_ADMIN_PAGE_PATH } from '../../page/admin-earning';
import { PROFILE_UPDATE_PAGE_PATH } from '../../page/profile-update';
import { CREATE_WITHDRAW_WALLET_PAGE_PATH } from '../../page/create-withdraw-wallet';
import { CREATE_WITHDRAW_GIFT_PAGE_PATH } from '../../page/create-withdraw-gift';
import { LEVEL_GIFT_PAGE_PATH } from '../../page/level-gift';
import { RATING_LIST_PAGE_PATH } from '../../page/rating-list';
import { FORTUNA_SPIN_WHEEL_PAGE_PATH } from '../../page/fortuna-spin-wheel';
import { FORTUNA_ADMIN_PAGE_PATH } from '../../page/admin-fortuna';
import { FORTUNA_PAGE_PATH } from '../../page/fortuna';

export const HEADER_TITLE: Record<string, string> = {
  [ACTIVITY_PAGE_PAGE_PATH]: 'HEADER.TITLE.ACTIVITY',
  [ANALYTIC_PAGE_PATH]: 'HEADER.TITLE.ANALYTIC',
  [BALANCE_PAGE_PATH]: 'HEADER.TITLE.BALANCE',
  [EDUCATION_PAGE_PATH]: 'HEADER.TITLE.EDUCATION',
  [EVENT_PAGE_PATH]: 'HEADER.TITLE.EVENT',
  [FAQ_PAGE_PATH]: 'HEADER.TITLE.FAQ',
  [PROFILE_UPDATE_PAGE_PATH]: 'HEADER.TITLE.PROFILE_UPDATE',
  [CREATE_WITHDRAW_WALLET_PAGE_PATH]:
    'HEADER.TITLE.CREATE_WITHDRAW_WALLET_PAGE_PATH',
  [CREATE_WITHDRAW_GIFT_PAGE_PATH]:
    'HEADER.TITLE.CREATE_WITHDRAW_GIFT_PAGE_PATH',
  [WITHDRAW_PAGE_PATH]: 'HEADER.TITLE.REQUEST',
  [USER_ADMIN_PAGE_PATH]: 'HEADER.TITLE.USERS_ADMIN',
  [EVENT_ADMIN_PAGE_PATH]: 'HEADER.TITLE.EVENT_ADMIN',
  [ADDITIONAL_PAGE_PATH]: 'HEADER.TITLE.ADDITIONAL',
  [EARNING_ADMIN_PAGE_PATH]: 'HEADER.TITLE.EARNING',
  [LEVEL_GIFT_PAGE_PATH]: 'HEADER.TITLE.LEVEL_GIFT',
  [RATING_LIST_PAGE_PATH]: 'HEADER.TITLE.RATING',
  [FORTUNA_SPIN_WHEEL_PAGE_PATH]: 'HEADER.TITLE.WHEEL',
  [FORTUNA_ADMIN_PAGE_PATH]: 'HEADER.TITLE.WHEEL_LIST',
  [FORTUNA_PAGE_PATH]: 'HEADER.TITLE.WHEEL_LIST',
};

export const HEADER_DESCRIPTION: Record<string, string> = {
  [FORTUNA_SPIN_WHEEL_PAGE_PATH]: 'HEADER.DESCRIPTION.WHEEL',
};
