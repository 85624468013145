import React from 'react';
import styled from 'styled-components';
import { TextElem } from '../../../common/text';
import { Spacing } from '../../../theme';

import { FORTUNA_ITEM_DATA_RAW_INTER } from '../constant';
import { FORTUNA_PRIZE_ENUM } from '../../../data/fortuna/constant';

export const Card: React.FC<{
  data: FORTUNA_ITEM_DATA_RAW_INTER;
}> = ({ data }) => {
  const withoutDollar = [
    FORTUNA_PRIZE_ENUM.IPHONE_16_PRO,
    FORTUNA_PRIZE_ENUM.AIRPODS_PRO,
    FORTUNA_PRIZE_ENUM.NOTHING,
    FORTUNA_PRIZE_ENUM.REPEAT_SPIN,
  ].includes(data.prize);

  return (
    <>
      <Container>
        <UserBlock>
          <TextElem
            color="white"
            type="semi-bold"
            size="semiSmall"
            tid={`ID ${data.user?.telegramId}`}
          />

          <TextElem
            color="textSecondary"
            type="medium"
            size="verySmall"
            tid={
              data.user?.fullName || data.user?.firstName || data.user?.userName
            }
          />
        </UserBlock>

        <Block>
          <TextElem
            color="default"
            type="semi-bold"
            size="semiSmall"
            tid={withoutDollar ? data.prize : `$${data.prize}`}
          />
        </Block>

        <DateBlock>
          <TextElem
            color="textSecondary"
            type="medium"
            size="small"
            tid={data.createDate}
          />
        </DateBlock>
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: ${Spacing(1)};
  align-items: center;
  justify-content: space-between;
`;

const Block = styled.div`
  overflow: hidden;
  min-width: 100px;
  max-width: 100px;
`;

const UserBlock = styled(Block)`
  display: flex;
  text-align: start;
  flex-direction: column;
  gap: ${Spacing(1)};
`;

const DateBlock = styled(Block)`
  min-width: 65px;
  max-width: 65px;
`;
