import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { STORE_INTER, USER_ROLE } from '../../data/auth/constant';

import { OutletContainer } from '../../epic/outlet';
import { WelcomeUnloginedPage, WELCOME_UNLOGINED_PAGE_PATH } from '../welcome';
import { ActivityPage, ACTIVITY_PAGE_PAGE_PATH } from '../activity';
import { ANALYTIC_PAGE_PATH, AnalyticPage } from '../analytic';
import { BALANCE_PAGE_PATH, BalancePage } from '../balance';
import { EDUCATION_PAGE_PATH, EducationPage } from '../education';
import { EVENT_PAGE_PATH, EventPage } from '../event';
import { USER_ADMIN_PAGE_PATH, UserAdminPage } from '../admin-user';
import { ADDITIONAL_PAGE_PATH, AdditionalPage } from '../additional';
import { FAQ_PAGE_PATH, FaqPage } from '../faq';
import { EARNING_ADMIN_PAGE_PATH, EarningAdminPage } from '../admin-earning';
import { AdminWithdrawPage, WITHDRAW_PAGE_PATH } from '../admin-withdraw';
import { EVENT_ADMIN_PAGE_PATH, EventAdminPage } from '../admin-event';
import { PROFILE_UPDATE_PAGE_PATH, ProfileUpdatePage } from '../profile-update';
import {
  CREATE_WITHDRAW_WALLET_PAGE_PATH,
  CreateWithdrawWalletPage,
} from '../create-withdraw-wallet';
import {
  CREATE_WITHDRAW_GIFT_PAGE_PATH,
  CreateWithdrawGiftPage,
} from '../create-withdraw-gift';
import { LEVEL_GIFT_PAGE_PATH, LevelGiftPage } from '../level-gift';
import { RATING_LIST_PAGE_PATH, RatingListPage } from '../rating-list';
import {
  FORTUNA_SPIN_WHEEL_PAGE_PATH,
  FortunaSpinWheelPage,
} from '../fortuna-spin-wheel';
import { FORTUNA_ADMIN_PAGE_PATH, FortunaAdminPage } from '../admin-fortuna';
import { FORTUNA_PAGE_PATH, FortunaPage } from '../fortuna';

export const Page: React.FC = () => {
  const state: STORE_INTER = useSelector((s: any) => s[AUTH_MODULE_NAME]);

  const isLogged = () => state.logged;
  const isAdmin = () => state.user?.role === USER_ROLE.ADMIN;

  return (
    <Router>
      <Switch>
        {isLogged() ? (
          <>
            <OutletContainer>
              <Route
                path={ACTIVITY_PAGE_PAGE_PATH}
                component={ActivityPage}
                exact
              />
              <Route path={FAQ_PAGE_PATH} component={FaqPage} exact />
              <Route
                path={RATING_LIST_PAGE_PATH}
                component={RatingListPage}
                exact
              />
              <Route
                path={PROFILE_UPDATE_PAGE_PATH}
                component={ProfileUpdatePage}
                exact
              />
              <Route
                path={FORTUNA_SPIN_WHEEL_PAGE_PATH}
                component={FortunaSpinWheelPage}
                exact
              />
              <Route
                path={LEVEL_GIFT_PAGE_PATH}
                component={LevelGiftPage}
                exact
              />
              <Route
                path={CREATE_WITHDRAW_WALLET_PAGE_PATH}
                component={CreateWithdrawWalletPage}
                exact
              />
              <Route
                path={CREATE_WITHDRAW_GIFT_PAGE_PATH}
                component={CreateWithdrawGiftPage}
              />
              <Route path={ANALYTIC_PAGE_PATH} component={AnalyticPage} exact />
              <Route path={BALANCE_PAGE_PATH} component={BalancePage} exact />
              <Route
                path={EDUCATION_PAGE_PATH}
                component={EducationPage}
                exact
              />

              <Route path={FORTUNA_PAGE_PATH} component={FortunaPage} exact />
              <Route path={EVENT_PAGE_PATH} component={EventPage} exact />

              <Redirect to={EVENT_PAGE_PATH} />

              {isAdmin() && (
                <>
                  <Route
                    path={EARNING_ADMIN_PAGE_PATH}
                    component={EarningAdminPage}
                    exact
                  />

                  <Route
                    path={FORTUNA_ADMIN_PAGE_PATH}
                    component={FortunaAdminPage}
                    exact
                  />
                  <Route
                    path={WITHDRAW_PAGE_PATH}
                    component={AdminWithdrawPage}
                    exact
                  />
                  <Route
                    path={USER_ADMIN_PAGE_PATH}
                    component={UserAdminPage}
                    exact
                  />
                  <Route
                    path={EVENT_ADMIN_PAGE_PATH}
                    component={EventAdminPage}
                    exact
                  />
                  <Route
                    path={ADDITIONAL_PAGE_PATH}
                    component={AdditionalPage}
                    exact
                  />
                </>
              )}
            </OutletContainer>
          </>
        ) : (
          <>
            <Route
              path={WELCOME_UNLOGINED_PAGE_PATH}
              component={WelcomeUnloginedPage}
              exact
            />
            <Redirect to={WELCOME_UNLOGINED_PAGE_PATH} />
          </>
        )}
      </Switch>
    </Router>
  );
};
