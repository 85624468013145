import React from 'react';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import { COLOR_ENUM } from '../../theme/color';
import { getMenuList } from './constant';
import { TextElem } from '../../common/text';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { useHistory, useLocation } from 'react-router';
import { STORE_INTER } from '../../data/auth/constant';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';

export const Container: React.FC = () => {
  const history = useHistory();

  const state: STORE_INTER = useSelector((s: any) => s[AUTH_MODULE_NAME]);
  const menuList = getMenuList(state.user?.role);

  const redirect = (path: string) => {
    history.push(path);
  };

  const router = useLocation();
  const pathname = router.pathname;

  const isActive = (path: string) => pathname.includes(path);

  return (
    <Wrapper>
      {menuList.map((e) => (
        <Card onClick={() => redirect(e.link)}>
          <img
            style={{ height: '18px', width: '18px' }}
            src={isActive(e.link) ? e.imgActive : e.img}
            alt="option"
          />
          <TextElem
            oneLine
            size="default"
            type="medium"
            color="white"
            tid={e.tid}
          />
        </Card>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(1)};
  padding: 0 ${Spacing(5)};
`;

const Card = styled.div`
  display: flex;
  gap: ${Spacing(2.5)};
  padding: ${Spacing(4)};
  transition: all ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  align-items: center;
  cursor: pointer;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;

  &:hover {
    background-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]};

    span {
      color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
    }
  }
`;
