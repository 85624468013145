import React from 'react';
import styled from 'styled-components';

import { Spacing } from '../../theme';
import { ButtonElem } from '../../common/button';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { i18n } from '../../lib/lang';
import { ModalConfirmElem } from '../../common/modal-confirm';

export const Component: React.FC<{
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  handleClickAction: Function;
  handleRedirect: Function;
  isNotExchange: boolean;
}> = ({
  isError,
  errorMessage,
  isSuccess,
  isLoading,
  handleClickAction,
  handleRedirect,
  isNotExchange,
}) => {
  return (
    <>
      <ButtonContainer>
        {/* {!isNotExchange && (
          <ModalConfirmElem
            action={handleClickAction}
            titleTid="titleTid"
            titleValue={{ value: 'titleValue' }}
            type="confirm"
          >
            <ButtonElem type="primary" tid="USER.GIFT.BUTTON.EXCHANGE" />
          </ModalConfirmElem>
        )} */}
        <ButtonElem
          onClick={handleRedirect}
          type="submit"
          tid="USER.GIFT.BUTTON.TAKE"
        />
      </ButtonContainer>
      {isError && <AlertActionElem tid={`ERROR.${errorMessage}`} />}
      {isSuccess && <AlertActionElem type="success" tid="Success" />}
      {isLoading && <LoaderElem />}
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
`;
