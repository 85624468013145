import React from 'react';
import styled, { css } from 'styled-components';

import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { COLOR_ENUM } from '../../theme/color';
import { TextElem } from '../../common/text';
import { useHistory, useLocation } from 'react-router';
import { ADMIN_PAGE, ALLOWED_FORTUNA_BALANCE_PAGES, data } from './constant';
import { VALUE_OPACITY_DATA, VALUE_OPACITY_ENUM } from '../../theme/value';
import { FortunaBalanceDataContainer } from '../fortuna-balance-data';

export const Component: React.FC = () => {
  const history = useHistory();

  const redirect = (path: string) => {
    history.push(path);
  };

  const router = useLocation();
  const pathname = router.pathname;
  const showFortunaBalance = ALLOWED_FORTUNA_BALANCE_PAGES.includes(
    router.pathname,
  );

  const isActive = (path: string) => pathname.includes(path);
  // const isAdminPage = (path: string) => ADMIN_PAGE.includes(path);

  // const menuData = isAdminPage(pathname) ? data.admin : data.user;

  return (
    <>
      {showFortunaBalance && <FortunaBalanceDataContainer />}
      <Wrapper>
        <Container>
          {data.user.map((e: any) => (
            <MenuElement
              isActive={isActive(e.path)}
              onClick={(event) => {
                event.preventDefault();
                redirect(e.path);
              }}
            >
              <ImgStyled src={isActive(e.path) ? e.imgActive : e.img} />
              {isActive(e.path) ? (
                <TextElem
                  color="default"
                  size="verySmall"
                  type="medium"
                  tid={e.tid}
                />
              ) : (
                <TextElem
                  size="verySmall"
                  type="medium"
                  color="textPrimary"
                  tid={e.tid}
                />
              )}
            </MenuElement>
          ))}
        </Container>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  padding: ${Spacing(3)};
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 100;
`;

const ImgStyled = styled.img`
  height: 22px;
  width: 22px;
`;

const MenuElement = styled.div<{ isActive: boolean }>`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  gap: ${Spacing(2)};
  justify-content: center;
  align-items: center;
  text-align: center;

  ${({ isActive }) =>
    !isActive &&
    css`
      &:hover {
        opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
      }
    `}
`;

const Container = styled.div<{}>`
  display: flex;
  gap: ${Spacing(1)};

  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  padding: ${Spacing(5)} ${Spacing(6)};
  width: 100%;
`;
