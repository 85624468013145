import React from 'react';
import styled from 'styled-components';
import shieldIcon from '../../asset/png/fortuna-shield.png';
import fortunaListIcon from '../../asset/svg/fortuna/fortuna-list.svg';

import miniShieldIcon from '../../asset/svg/fortuna/mini-shield.svg';

import { TextElem } from '../../common/text';

import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { Spacing } from '../../theme';
import { LoaderElem } from '../../common/loader';
import { ButtonElem } from '../../common/button';
import { useHistory } from 'react-router';
import { FORTUNA_SPIN_WHEEL_PAGE_PATH } from '../../page/fortuna-spin-wheel';
import { FORTUNA_PAGE_PATH } from '../../page/fortuna';
import { getLocalizedAttempts } from '../../lib/util/getLocalizedAttempts';

export const Component: React.FC<{
  fortunaBalance: number;
  isLoading?: boolean;
  isSecondary?: boolean;
}> = ({ isLoading, fortunaBalance, isSecondary = false }) => {
  const history = useHistory();

  const redirect = (path: string) => {
    history.push(path);
  };
  const isChance = fortunaBalance >= 1;

  const localizedBalance = getLocalizedAttempts(fortunaBalance);
  return (
    <>
      {isLoading && <LoaderElem />}
      {isChance && !isSecondary && (
        <Wrapper onClick={() => redirect(FORTUNA_SPIN_WHEEL_PAGE_PATH)}>
          <TextContainer>
            <TextBlockPrimary>
              <TextElem
                type="semi-bold"
                size="semiSmall"
                color="backgroundPrimary"
                tid="FORTUNA.WHEEL"
              />
            </TextBlockPrimary>
            <TextBlockSecondary>
              <TextElem
                type="semi-bold"
                size="small"
                color="default"
                oneLine
                tid={localizedBalance}
              />
            </TextBlockSecondary>
          </TextContainer>
          <WheelWrapper>
            <WheelImage src={shieldIcon} alt="Wheel of Fortune" />
          </WheelWrapper>
        </Wrapper>
      )}
      {isSecondary && (
        <FlexElem>
          <ButtonElemStyled
            type="primary"
            sizeText="default"
            tid="FORTUNA.WHEEL_BUTTON"
            onClick={() => redirect(FORTUNA_SPIN_WHEEL_PAGE_PATH)}
            iconRight={miniShieldIcon}
            iconSize="large"
          />
          <ChipStyled>
            <TextElem
              size="small"
              type="semi-bold"
              color="default"
              oneLine
              tid={localizedBalance}
            />
          </ChipStyled>

          <ButtonElemIconStyled
            onClick={() => redirect(FORTUNA_PAGE_PATH)}
            iconRight={fortunaListIcon}
            iconSize="large"
            fill="icon"
          />
        </FlexElem>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  z-index: 200;
  position: fixed;
  right: 50px;
  bottom: 140px;
  background: transparent;
`;

const TextContainer = styled.div`
  display: grid;
  position: relative;
`;

const TextBlockPrimary = styled.div`
  padding: ${Spacing(2.5)} ${Spacing(12.5)} ${Spacing(2.5)} ${Spacing(5)};

  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.CONTENT
  ]}px;

  background: linear-gradient(90deg, #f4e187, #e5b45f);
  position: relative;
  z-index: 1;
  top: 0px;
  right: 0px;
`;

const TextBlockSecondary = styled.div`
  padding: ${Spacing(2)} ${Spacing(15)} ${Spacing(2)} ${Spacing(5)};
  border-radius: 4px;
  height: 28px;
  background: linear-gradient(90deg, #f4e1871a, #e5b45f1a);

  position: absolute;
  top: 40px;
  left: 65px;
  z-index: 0;
`;

const WheelWrapper = styled.div`
  position: absolute;
  right: -50px;
  top: -16px;
  width: 100px;
  height: 100px;
  z-index: 2;
  overflow: hidden;
`;

const WheelImage = styled.img`
  width: 100px;
  height: 100px;
  overflow: hidden;
  object-fit: cover;
  z-index: 2;
`;

const FlexElem = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  gap: ${Spacing(2)};
`;

const ChipStyled = styled.div`
  border-radius: 4px;
  height: 40px;

  padding: ${Spacing(3)} ${Spacing(4)};
  background: linear-gradient(90deg, #f4e1871a, #e5b45f1a);
`;

const ButtonElemStyled = styled(ButtonElem)`
  padding: ${Spacing(2)} ${Spacing(3.5)};
  max-height: 40px;
`;

const ButtonElemIconStyled = styled(ButtonElem)`
  max-height: 40px;
  max-width: 40px;

  border-radius: 4px;
  padding: ${Spacing(2.5)};
  background: linear-gradient(90deg, #f4e1871a, #e5b45f1a);
`;
