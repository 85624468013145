import React from 'react';
import styled from 'styled-components';
import { GridElem } from '../../../common/grid';
import { TextElem } from '../../../common/text';
import { ButtonElem } from '../../../common/button';
import { ReactComponent as PrizeBackground } from '../../../asset/svg/fortuna/prize-background.svg';
import { ReactComponent as PrizeObjectBackground } from '../../../asset/svg/fortuna/prize-object-background.svg';

import airpods from '../../../asset/png/fortuna-airpods-big.png';
import iphone from '../../../asset/png/fortuna-iphone-big.png';
import spinAgain from '../../../asset/svg/fortuna/spin-again-gold.svg';
import { ModalContent, ModalOverlay } from '../../../common/modal/elem';
import { Spacing } from '../../../theme';
import { FORTUNA_PRIZE_ENUM, Prize } from '../../../data/fortuna/constant';

interface ModalProps {
  prize: Prize;
  onClose: () => void;
}

export const ModalPrize: React.FC<ModalProps> = ({ prize, onClose }) => {
  const isPhysicalObject =
    prize.value === FORTUNA_PRIZE_ENUM.IPHONE_16_PRO ||
    prize.value === FORTUNA_PRIZE_ENUM.AIRPODS_PRO;

  const isIphone = prize.value === FORTUNA_PRIZE_ENUM.IPHONE_16_PRO;

  const isSpinAgain = prize.value === FORTUNA_PRIZE_ENUM.REPEAT_SPIN;

  return (
    <>
      <ModalOverlay>
        <ModalContentStyled open={!!prize}>
          <Container spacing={7}>
            <GridElem spacing={2}>
              <TextElem
                size="medium"
                color="white"
                type="semi-bold"
                style={{ textAlign: 'center' }}
                tid="FORTUNA.MODAL.TITLE"
              />
              <TextElem
                size="semiSmall"
                color="textPrimary"
                type="regular"
                tid="FORTUNA.MODAL.DESCRIPTION"
                style={{ textAlign: 'center' }}
              />
            </GridElem>
            <>
              {isPhysicalObject ? (
                <GridElem spacing={0} style={{ justifyContent: 'center' }}>
                  <PrizeObjectBackgroundStyled />
                  <BlurObjectEffect />

                  <CenteredContentPhysical>
                    <PrizeImage
                      src={isIphone ? iphone : airpods}
                      alt={prize.label}
                    />
                  </CenteredContentPhysical>
                </GridElem>
              ) : (
                <>
                  <PrizeBackgroundStyled />
                  <BlurEffect />
                  <CenteredContent>
                    {isSpinAgain ? (
                      <SpinImage src={spinAgain} alt={prize.label} />
                    ) : (
                      <TextElemStyled
                        size="medium"
                        color="default"
                        type="bold"
                        tid={prize.label}
                      />
                    )}
                  </CenteredContent>
                </>
              )}
            </>
            <GridElem spacing={2.5}>
              <TextElem
                size="medium"
                color="default"
                type="bold"
                style={{ textAlign: 'center' }}
                tid={prize.label}
              />
              <TextElem
                style={{ textAlign: 'center' }}
                size="semiSmall"
                color="white"
                type="medium"
                tid="FORTUNA.MODAL.TRY_AGAIN"
              />
            </GridElem>

            <ButtonElem
              type="primary"
              onClick={onClose}
              tid="FORTUNA.MODAL.BUTTON"
            />
          </Container>
        </ModalContentStyled>
      </ModalOverlay>
    </>
  );
};

const ModalContentStyled = styled(ModalContent)<{ open: boolean }>`
  padding: ${Spacing(6)} ${Spacing(5)};
  border-radius: 12px;
  max-height: 70%;
`;

const Container = styled(GridElem)``;

const PrizeBackgroundStyled = styled(PrizeBackground)`
  position: relative;
  width: 92px;
  height: 92px;
  margin: 0 auto;
`;

const PrizeObjectBackgroundStyled = styled(PrizeObjectBackground)`
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 232px;
  max-height: 232px;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const BlurObjectEffect = styled.div`
  position: absolute;
  width: 70px;
  height: 70px;
  background: radial-gradient(circle, #f4e187 30%, #e5b45f 70%);
  filter: blur(100px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
`;

const BlurEffect = styled(BlurObjectEffect)`
  width: 60px;
  height: 60px;
  top: 40%;
`;

const CenteredContent = styled.div`
  position: absolute;
  top: 41%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const CenteredContentPhysical = styled.div`
  position: relative;
  overflow: hidden;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const TextElemStyled = styled(TextElem)`
  width: max-content;
`;

const PrizeImage = styled.img`
  width: 265px;
  height: 200px;
`;

const SpinImage = styled.img`
  width: 44px;
  height: 44px;
`;
