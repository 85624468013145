import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';
import { TextElem } from '../text';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_TYPE,
} from '../../theme/size';

import { Spacing } from '../../theme';
import checkSvg from '../../asset/svg/common/check.svg';

export const Elem: React.FC<{
  name?: string;
  tid?: string;
  tvalue?: object;
  text?: string;
  textSize?: SIZE_FONT_TYPE;
  checked?: boolean;
  onClick?: any;
  isLoading?: boolean;
  onChange?: any;
}> = ({
  checked = false,
  onClick,
  tid,
  tvalue,
  text = null,
  textSize = 'main',
  isLoading,
  onChange,
  name,
}) => {
  const [status, setStatus] = useState<boolean>(checked);

  useEffect(() => {
    console.log('Checked prop updated: ', checked);

    setStatus(checked);
  }, [checked]);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isLoading) {
      setStatus(!status);
      if (onClick) {
        onClick();
      }
    }

    const target = e.target as HTMLInputElement;
    const event = {
      ...e,
      target: {
        ...target,
        name,
        value: !status,
      },
    };

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Container onClick={handleClick}>
      <StyledCheckbox
        type="checkbox"
        checked={status}
        disabled={isLoading}
        name={name}
      />
      <TextElem size="alert" color="white" tid={tid} tvalue={tvalue}>
        {text && text}
      </TextElem>
    </Container>
  );
};

const StyledCheckbox = styled.input`
  width: 16px;
  height: 16px;
  appearance: none;
  background: transparent;
  border-radius: 4px;
  transition: background 0.3s, border-color 0.3s;
  background: ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]};

  &:checked {
    background: ${({ theme }) => theme[COLOR_ENUM.BUTTON_PRIMARY]};
    border-color: none;
  }

  &:checked::before {
    content: url(${checkSvg});
    display: block;
    margin: auto;
    height: 14px;
    width: 14px;
    position: relative;
    top: 1px;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${Spacing(3)};
  cursor: pointer;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BLOCK]}px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]};
  gap: ${Spacing(2)};
  span {
    height: 17px;
  }
`;
