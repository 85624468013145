import { USER_EARNING_STATUS } from '../../epic/earning-admin-list/constant';
import { SKILL_ENUM } from '../../epic/profile-update/constant';
import { i18n } from '../../lib/lang';
import { BALANCE_PAGE_PATH } from '../../page/balance';
import { EVENT_PAGE_PATH } from '../../page/event';
import { USER_ROLE } from '../auth/constant';

export const USER_LIST_SHORT_MODULE_NAME = 'USER_LIST_SHORT_MODULE_NAME';

export const FORTUNA_BALANCE_MODULE_NAME = 'FORTUNA_BALANCE_MODULE_NAME';

export interface USER_ID_INTER {
  telegramId?: number;
  invitedTelegramId?: number;
}

export enum USER_GIFT {
  MERCH = 1,
  AIRPODS = 2,
  WALLET = 3,
  APPLE_WATCH = 4,
  IPHONE_MACBOOK = 5,
  FORTUNA_IPHONE_16_PRO = 6,
  FORTUNA_AIRPODS_PRO = 7,
}

export enum TOP_USER_BY {
  INVITED_AMOUNT = 1,
  SELLS_AMOUNT = 2,
  SELLS_SUM = 3,
}

export const TOP_USER_BY_VIEW = {
  [TOP_USER_BY.INVITED_AMOUNT]: 'USER.RATING.TOP_BY.INVITED_AMOUNT',
  [TOP_USER_BY.SELLS_AMOUNT]: 'USER.RATING.TOP_BY.SELLS_AMOUNT',
  [TOP_USER_BY.SELLS_SUM]: 'USER.RATING.TOP_BY.SELLS_SUM',
};

export interface USER_ITEM_DATA_RAW_INTER {
  id: string;
  createDate: string;
  role: USER_ROLE;
  telegramId: number;
  userName: string;
  firstName: string;
  lastName: string;
  languageCode: string;
  allowsWriteToPm: boolean;
  balance: number;
  fortunaBalance: number;

  earning: {
    amount: number;
    isPerson: boolean;
    status: USER_EARNING_STATUS;
    personAmount: number;
  }[];
  level: number;
  gift: USER_GIFT[];
  email: string;
  fullName: string;
  phone: string;
  invited: {
    telegramId: string;
    userName: string;
  };
}

export interface RATING_ITEM_USER_INTER {
  rating: number;
  totalAmount: number;
  user: USER_ITEM_DATA_RAW_INTER;
}

export interface RATING_LIST_USER_INTER {
  list: RATING_ITEM_USER_INTER[];
  currentUser: RATING_ITEM_USER_INTER;
}

export interface USER_ITEM_LIST_DATA_RAW_INTER {
  list: USER_ITEM_DATA_RAW_INTER[];
  count?: number;
}

export interface USER_ITEM_DATA_INTER extends USER_ITEM_DATA_RAW_INTER {
  referalUser: number;
  pendingBalance: number;
  totalBalance: number;
}

export interface USER_ITEM_LIST_DATA_INTER {
  list: USER_ITEM_DATA_INTER[];
  count?: number;
  isEmpty: boolean;
}

export const API = {
  FORTUNA: {
    TYPE: 'GET',
    URL: '/user/fortuna-balance',
  },
  LIST: {
    TYPE: 'GET',
    URL: '/user/admin/user',
  },
  LIST_SHORT: {
    TYPE: 'GET',
    URL: (param: string) => `/user/option-list/${param}`,
  },
};

// export enum USER_ROLE {
//   BLOCKED = 'BLOCKED',
//   USER = 'USER',
//   MODER = 'MODER',
//   ADMIN = 'ADMIN',
// }

export const USER_ROLE_OPTION_LIST = [
  {
    value: USER_ROLE.ADMIN,
    label: i18n.t('USER.ROLE.ADMIN'),
  },

  {
    value: USER_ROLE.USER,
    label: i18n.t('USER.ROLE.USER'),
  },

  {
    value: USER_ROLE.CURATOR,
    label: i18n.t('USER.ROLE.CURATOR'),
  },

  {
    value: USER_ROLE.ANALYST,
    label: i18n.t('USER.ROLE.ANALYST'),
  },
];
