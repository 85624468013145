import { i18n } from '../../lib/lang';

export enum FORTUNA_PRIZE_ENUM {
  FIFTY_CENTS = '0.5',
  ONE_DOLLAR = '1',
  FIVE_DOLLARS = '5',
  TEN_DOLLARS = '10',
  FIFTY_DOLLARS = '50',
  ONE_HUNDRED_DOLLARS = '100',
  AIRPODS_PRO = 'Airpods Pro',
  IPHONE_16_PRO = 'iPhone 16 Pro',
  REPEAT_SPIN = 'Repeat Spin',
  NOTHING = 'Nothing',
}

export const FORTUNA_PRIZE_SEGMENT: Record<FORTUNA_PRIZE_ENUM, number> = {
  [FORTUNA_PRIZE_ENUM.IPHONE_16_PRO]: 1,
  [FORTUNA_PRIZE_ENUM.TEN_DOLLARS]: 2,
  [FORTUNA_PRIZE_ENUM.ONE_HUNDRED_DOLLARS]: 3,
  [FORTUNA_PRIZE_ENUM.REPEAT_SPIN]: 4,
  [FORTUNA_PRIZE_ENUM.ONE_DOLLAR]: 5,
  [FORTUNA_PRIZE_ENUM.AIRPODS_PRO]: 6,
  [FORTUNA_PRIZE_ENUM.FIFTY_DOLLARS]: 7,
  [FORTUNA_PRIZE_ENUM.FIFTY_CENTS]: 8,
  [FORTUNA_PRIZE_ENUM.NOTHING]: 9,
  [FORTUNA_PRIZE_ENUM.FIVE_DOLLARS]: 10,
};

export interface Prize {
  value: FORTUNA_PRIZE_ENUM;
  label: string;
  cellIndex: number;
}

export const PRIZES: Prize[] = [
  {
    value: FORTUNA_PRIZE_ENUM.FIFTY_CENTS,
    label: '$ 0.50',
    cellIndex: FORTUNA_PRIZE_SEGMENT[FORTUNA_PRIZE_ENUM.FIFTY_CENTS],
  },
  {
    value: FORTUNA_PRIZE_ENUM.FIFTY_DOLLARS,
    label: '$ 50',
    cellIndex: FORTUNA_PRIZE_SEGMENT[FORTUNA_PRIZE_ENUM.FIFTY_DOLLARS],
  },

  {
    value: FORTUNA_PRIZE_ENUM.NOTHING,
    label: 'Oops.',
    cellIndex: FORTUNA_PRIZE_SEGMENT[FORTUNA_PRIZE_ENUM.NOTHING], // 10
  },

  {
    value: FORTUNA_PRIZE_ENUM.TEN_DOLLARS,
    label: '$ 10',
    cellIndex: FORTUNA_PRIZE_SEGMENT[FORTUNA_PRIZE_ENUM.TEN_DOLLARS],
  },

  {
    value: FORTUNA_PRIZE_ENUM.IPHONE_16_PRO,
    label: 'Iphone 16 Pro',
    cellIndex: FORTUNA_PRIZE_SEGMENT[FORTUNA_PRIZE_ENUM.IPHONE_16_PRO], // 8
  },
  {
    value: FORTUNA_PRIZE_ENUM.FIVE_DOLLARS,
    label: '$ 5',
    cellIndex: FORTUNA_PRIZE_SEGMENT[FORTUNA_PRIZE_ENUM.FIVE_DOLLARS], // 3
  },
  {
    value: FORTUNA_PRIZE_ENUM.ONE_HUNDRED_DOLLARS,
    label: '$ 100',
    cellIndex: FORTUNA_PRIZE_SEGMENT[FORTUNA_PRIZE_ENUM.ONE_HUNDRED_DOLLARS],
  },
  {
    value: FORTUNA_PRIZE_ENUM.ONE_DOLLAR,
    label: '$ 1',
    cellIndex: FORTUNA_PRIZE_SEGMENT[FORTUNA_PRIZE_ENUM.ONE_DOLLAR],
  },
  {
    value: FORTUNA_PRIZE_ENUM.REPEAT_SPIN,
    label: 'Spin again',
    cellIndex: FORTUNA_PRIZE_SEGMENT[FORTUNA_PRIZE_ENUM.REPEAT_SPIN],
  },
  {
    value: FORTUNA_PRIZE_ENUM.AIRPODS_PRO,
    label: 'AirPods Pro',
    cellIndex: FORTUNA_PRIZE_SEGMENT[FORTUNA_PRIZE_ENUM.AIRPODS_PRO],
  },
];

export const FORTUNA_PRIZE_VIEW = {
  [FORTUNA_PRIZE_ENUM.ONE_DOLLAR]: 'FORTUNA.PRIZE.ONE_DOLLAR',
  [FORTUNA_PRIZE_ENUM.FIFTY_CENTS]: 'FORTUNA.PRIZE.FIFTY_CENTS',
  [FORTUNA_PRIZE_ENUM.FIVE_DOLLARS]: 'FORTUNA.PRIZE.FIVE_DOLLARS',
  [FORTUNA_PRIZE_ENUM.FIFTY_DOLLARS]: 'FORTUNA.PRIZE.FIFTY_DOLLARS',
  [FORTUNA_PRIZE_ENUM.ONE_HUNDRED_DOLLARS]: 'FORTUNA.PRIZE.ONE_HUNDRED_DOLLARS',
  [FORTUNA_PRIZE_ENUM.REPEAT_SPIN]: 'FORTUNA.PRIZE.REPEAT_SPIN',
  [FORTUNA_PRIZE_ENUM.NOTHING]: 'FORTUNA.PRIZE.NOTHING',
  [FORTUNA_PRIZE_ENUM.IPHONE_16_PRO]: 'FORTUNA.PRIZE.IPHONE_16_PRO',
  [FORTUNA_PRIZE_ENUM.AIRPODS_PRO]: 'FORTUNA.PRIZE.AIRPODS_PRO',
  [FORTUNA_PRIZE_ENUM.TEN_DOLLARS]: 'FORTUNA.PRIZE.TEN_DOLLARS',
};

export const FORTUNA_PRIZE_LIST = [
  {
    label: i18n.t(FORTUNA_PRIZE_VIEW[FORTUNA_PRIZE_ENUM.FIFTY_CENTS]),
    value: FORTUNA_PRIZE_ENUM.FIFTY_CENTS,
  },
  {
    label: i18n.t(FORTUNA_PRIZE_VIEW[FORTUNA_PRIZE_ENUM.ONE_DOLLAR]),
    value: FORTUNA_PRIZE_ENUM.ONE_DOLLAR,
  },
  {
    label: i18n.t(FORTUNA_PRIZE_VIEW[FORTUNA_PRIZE_ENUM.FIVE_DOLLARS]),
    value: FORTUNA_PRIZE_ENUM.FIVE_DOLLARS,
  },
  {
    label: i18n.t(FORTUNA_PRIZE_VIEW[FORTUNA_PRIZE_ENUM.TEN_DOLLARS]),
    value: FORTUNA_PRIZE_ENUM.TEN_DOLLARS,
  },
  {
    label: i18n.t(FORTUNA_PRIZE_VIEW[FORTUNA_PRIZE_ENUM.FIFTY_DOLLARS]),
    value: FORTUNA_PRIZE_ENUM.FIFTY_DOLLARS,
  },
  {
    label: i18n.t(FORTUNA_PRIZE_VIEW[FORTUNA_PRIZE_ENUM.ONE_HUNDRED_DOLLARS]),
    value: FORTUNA_PRIZE_ENUM.ONE_HUNDRED_DOLLARS,
  },
  {
    label: i18n.t(FORTUNA_PRIZE_VIEW[FORTUNA_PRIZE_ENUM.IPHONE_16_PRO]),
    value: FORTUNA_PRIZE_ENUM.IPHONE_16_PRO,
  },

  {
    label: i18n.t(FORTUNA_PRIZE_VIEW[FORTUNA_PRIZE_ENUM.AIRPODS_PRO]),
    value: FORTUNA_PRIZE_ENUM.AIRPODS_PRO,
  },
  {
    label: i18n.t(FORTUNA_PRIZE_VIEW[FORTUNA_PRIZE_ENUM.REPEAT_SPIN]),
    value: FORTUNA_PRIZE_ENUM.REPEAT_SPIN,
  },
  {
    label: i18n.t(FORTUNA_PRIZE_VIEW[FORTUNA_PRIZE_ENUM.NOTHING]),
    value: FORTUNA_PRIZE_ENUM.NOTHING,
  },
];
