import React from 'react';
import styled, { css } from 'styled-components';

import { AlertActionElem } from '../../common/alert-action';
import { GridElem } from '../../common/grid';
import { RATING_ITEM_USER_INTER } from '../../data/user/constant';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import { SkeletonDataElem } from '../../common/skeleton-data';
import { EmptyListElem } from '../../common/empty-list-elem';
import { TextElem } from '../../common/text';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { TabElem } from '../../common/tab';

import { RatingFilters, TOP_USER_BY_TAB } from './constant';
import { FieldTimeInputElem } from '../../common/field-time-input';
import { getLocalizedAmount } from '../../lib/util/getLocalizedAmount';

export const Component: React.FC<{
  list?: RATING_ITEM_USER_INTER[];
  userRating: RATING_ITEM_USER_INTER;
  filter: RatingFilters;
  onChangeTime: (_first: any, second: any) => void;
  isLoading?: boolean;
  isSuccess?: boolean;
  isAdmin?: boolean;
  isError?: boolean;
  errorMessage?: string;
  updateFilter: (key: string, value: string | null | string[]) => void;

  isAccountInfo: boolean;
}> = ({
  list,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  userRating,
  isAdmin,
  filter,
  onChangeTime,
  updateFilter,
  isAccountInfo,
}) => {
  const isEmpty = list && list?.length < 1;

  const type = filter.type;
  return (
    <GridElem spacing={4}>
      <GridElem spacing={0}>
        {isAdmin && <FieldTimeInputElem allowClear onChange={onChangeTime} />}

        <TabElem
          active={filter.type}
          setActive={(value: any) => updateFilter('type', value)}
          tabList={TOP_USER_BY_TAB}
        />
      </GridElem>

      {isLoading && <SkeletonDataStyled />}
      {isError && <AlertActionElem text={errorMessage} />}
      <GridStyled>
        {isEmpty && !isLoading && (
          <EmptyListElem
            titleTid="USER.LIST.IS_EMPTY_TITLE"
            descriptionTid="USER.LIST.IS_EMPTY_DESCRIPTION"
          />
        )}

        {Array.isArray(list) &&
          isSuccess &&
          list.map((item) => {
            return (
              <CardContainer>
                <Card
                  isFirst={item?.rating === 1}
                  isSecond={item?.rating === 2}
                  isThird={item?.rating === 3}
                  key={item?.user.telegramId}
                >
                  <StartColumn>
                    {item?.rating === 1 && (
                      <RatingImg src="./assets/user/rating-1.svg" />
                    )}
                    {item?.rating === 2 && (
                      <RatingImg src="./assets/user/rating-2.svg" />
                    )}
                    {item?.rating === 3 && (
                      <RatingImg src="./assets/user/rating-3.svg" />
                    )}
                    {item?.rating > 3 && (
                      <NumberProfile>
                        <TextElem
                          type="bold"
                          color="textPrimary"
                          size="small"
                          tid={item?.rating}
                        />
                      </NumberProfile>
                    )}
                    <UserProfileContainer>
                      <TextElem
                        type="semi-bold"
                        size="default"
                        color="white"
                        oneLine
                        style={{ maxWidth: '150px' }}
                        tid={
                          item?.user?.fullName
                            ? item.user.fullName
                            : `${item?.user?.firstName} ${
                                item?.user?.lastName || ''
                              }`
                        }
                      />
                    </UserProfileContainer>
                  </StartColumn>
                  <EndColumn>
                    <TextElem
                      size="small"
                      type="semi-bold"
                      color="default"
                      tid={getLocalizedAmount(item.totalAmount, type)}
                    />
                  </EndColumn>
                </Card>
              </CardContainer>
            );
          })}

        {isAccountInfo && userRating.rating > 10 && (
          <>
            <Divider />
            <CardContainer>
              <Card isFirst={false} isSecond={false} isThird={false}>
                <StartColumn>
                  <NumberProfile>
                    <TextElem
                      type="bold"
                      color="white"
                      size="small"
                      tid={userRating.rating}
                    />
                  </NumberProfile>
                  <UserProfileContainer>
                    <TextElem
                      type="semi-bold"
                      size="default"
                      color="white"
                      oneLine
                      style={{ maxWidth: '150px' }}
                      tid={
                        userRating.user?.fullName
                          ? userRating.user.fullName
                          : `${userRating.user?.firstName} ${
                              userRating.user?.lastName || ''
                            }`
                      }
                    />
                  </UserProfileContainer>
                </StartColumn>
                <EndColumn>
                  <TextElem
                    size="small"
                    type="semi-bold"
                    color="default"
                    tid={getLocalizedAmount(userRating.totalAmount, type)}
                  />
                </EndColumn>
              </Card>
            </CardContainer>
          </>
        )}
      </GridStyled>
    </GridElem>
  );
};

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DIVIDER]};
`;

const RatingImg = styled.img`
  width: 25px;
  height: 24px;
`;

const EndColumn = styled.div`
  display: flex;
  width: 80px;
  gap: ${Spacing(1)};
  justify-content: flex-end;
  align-items: center;
`;

const StartColumn = styled.div`
  display: flex;
  gap: ${Spacing(3)};
  align-items: center;
`;

const NumberProfile = styled.div`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  width: 25px;
  height: 25px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UserProfileContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  align-items: center;
`;

const Card = styled.div<{
  isFirst?: boolean;
  isSecond?: boolean;
  isThird?: boolean;
}>`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  justify-content: space-between;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  padding: 13px;
  display: flex;
  gap: ${Spacing(2)};
  align-items: center;

  ${({ isFirst, isSecond, isThird }) => {
    if (isFirst) {
      return css`
        border: double 1px transparent;
        background-image: linear-gradient(#1b1b1c, #1b1b1c),
          linear-gradient(#f4e187bf, #e5b45fbf);
        background-origin: border-box;
        background-clip: padding-box, border-box;
      `;
    } else if (isSecond) {
      return css`
        border: 1px solid rgba(232, 230, 235, 0.75);
      `;
    } else if (isThird) {
      return css`
        border: 1px solid rgba(236, 157, 78, 0.75);
      `;
    }
  }}
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
`;

const SkeletonDataStyled = styled(SkeletonDataElem)`
  margin-top: ${Spacing(6)};
`;

const GridStyled = styled(GridElem)`
  grid-auto-columns: auto max-content max-content;
  row-gap: ${Spacing(3)};
`;
