import i18n from 'i18next';
import { addMinutes, format } from 'date-fns';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/ru';

export const convertDateToListTimezone = (date: string): string => {
  moment.locale('ru');

  const timezone = moment.tz.guess();

  const localDate = moment.tz(date, timezone);

  return localDate.fromNow();
};

export const convertDateToDDMMYYYY = (date: string) => {
  return moment(date).format(i18n.t('LANG.DATA.DD_MM_YYYY'));
};

export const convertDateToList = (date: string): string => {
  return moment(date, 'YYYYMMDD').fromNow();

  // .calendar({
  //   lastDay: '[Yesterday]',
  //   sameDay: '[Today]',
  //   nextDay: '[Tomorrow]',
  //   lastWeek: 'dddd [days ago]',
  //   nextWeek: '[Next] dddd',
  //   sameElse: 'MMMM D',
  // });
};

export const convertDateTime = (date: string) => {
  moment.locale('ru');

  return moment(date).format(i18n.t('LANG.DATA.DATE_TIME'));
};

export const convertDateTimeWithoutZone = (date: string) => {
  return moment(date).utcOffset(0, false).format(i18n.t('LANG.DATA.DATE_TIME'));
};

export const convertDayAndTimeWithoutZone = (date: string) => {
  return moment(date)
    .utcOffset(0, false)
    .format(i18n.t('LANG.DATA.DAY_AND_TIME'));
};

export const convertDayWithoutZone = (date: string) => {
  return moment(date).utcOffset(0, false).format(i18n.t('LANG.DATA.DAY'));
};

export const convertTimeWithoutZone = (date: string) => {
  return moment(date).utcOffset(0, false).format(i18n.t('LANG.DATA.TIME'));
};

export const convertDate = (date: string) => {
  return moment(date).format(i18n.t('LANG.DATA.DATE'));
};

export const convertTime = (date: string) => {
  return moment(date).format(i18n.t('LANG.DATA.ONLY_TIME'));
};

export const formatDateToUtc = (date: Date) => {
  try {
    const newDate = format(
      addMinutes(date, date.getTimezoneOffset()),
      "yyyy-MM-dd'T'HH:mm:ss",
    );
    return newDate;
  } catch (error) {
    console.log(error);
  }
};

export const convertPaymentItemOrderPeriod = (date: string) => {
  return moment(date).format(i18n.t('CONVERT.PAYMENT_ORDER_PERIOD'));
};
