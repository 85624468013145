import { FORTUNA_PRIZE_ENUM } from '../../data/fortuna/constant';
import { USER_ITEM_DATA_RAW_INTER } from '../../data/user/constant';

export const MODULE_NAME = 'FORTUNA_ADMIN_LIST_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: (params: string) => `/fortuna/admin-list/${params}`,
};

export interface FORTUNA_ITEM_DATA_RAW_INTER {
  id: string;
  prize: FORTUNA_PRIZE_ENUM;
  user: USER_ITEM_DATA_RAW_INTER;
  createDate: string;
}

export interface FORTUNA_ITEM_LIST_DATA_RAW_INTER {
  list: FORTUNA_ITEM_DATA_RAW_INTER[];
  page?: number;
  pageSize?: number;
  total?: number;
}

// export interface TASK_ITEM_DATA_INTER
//   extends Omit<FORTUNA_ITEM_DATA_RAW_INTER, ''> {
//   prizeView?: string;

// }
