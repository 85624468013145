import { API } from './constant';
import { HttpRequest } from '../../lib/http';

interface GetFortunaResultDataDto {
  prize: number;
}

export const action = async (): Promise<GetFortunaResultDataDto> => {
  try {
    const response: any = await HttpRequest({
      method: API.TYPE,
      url: API.URL,
    });

    return response as GetFortunaResultDataDto;
  } catch (error) {
    throw error;
  }
};
