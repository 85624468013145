import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { FORTUNA_BALANCE_MODULE_NAME } from '../../data/user/constant';
import { getFortunaBalance } from '../../data/user/action';

export const useFortunaBalance = () => {
  const queryClient = useQueryClient();

  const { data: fortunaBalance, isLoading } = useQuery(
    [FORTUNA_BALANCE_MODULE_NAME],
    getFortunaBalance,
    {
      staleTime: 0,
      cacheTime: 60000,
    },
  );
  const definedFortunaBalance = fortunaBalance || 0;
  const invalidateFortunaBalance = useCallback(() => {
    queryClient.invalidateQueries([FORTUNA_BALANCE_MODULE_NAME]);
    queryClient.refetchQueries([FORTUNA_BALANCE_MODULE_NAME]);
  }, [queryClient]);

  return {
    fortunaBalance: definedFortunaBalance,
    isLoading,
    invalidateFortunaBalance,
  };
};
