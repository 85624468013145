import i18next from 'i18next';

export const getLocalizedAttempts = (attempts: number) => {
    const attemptsKey = attempts.toString();
  
    if (i18next.exists(`FORTUNA.ATTEMPTS.${attemptsKey}`)) {
      return i18next.t(`FORTUNA.ATTEMPTS.${attemptsKey}`, { count: attempts });
    }
  
    return i18next.t('FORTUNA.ATTEMPTS.default', { count: attempts });
  };
  