import React, { useState } from 'react';
import { action } from './action';
import { Component } from './component';
import { useFortunaBalance } from '../../lib/util/useFortunaBalance';
import { Prize, PRIZES } from '../../data/fortuna/constant';
import { calculateRotation, INITIAL_ROTATION } from './constant';
import { USER_BALANCE_ANALYTIC_MODULE_NAME } from '../user-balance-analytic';
import { useQueryClient } from 'react-query';

export const Container: React.FC = () => {
  const query = useQueryClient();
  const { fortunaBalance, invalidateFortunaBalance } = useFortunaBalance();
  const [isSpinning, setIsSpinning] = useState(false);
  const [prize, setPrize] = useState<Prize | null>(null);
  const [rotation, setRotation] = useState(INITIAL_ROTATION);

  const handleSpin = async () => {
    if (fortunaBalance <= 0 || isSpinning) return;
    setIsSpinning(true);
    try {
      const result = await action();
      if (!result || !result.prize) {
        throw new Error('Не вдалося отримати приз');
      }

      const prizeItem = PRIZES.find((item) => item.cellIndex === result.prize);
      if (!prizeItem) {
        throw new Error('Приз не знайдено');
      }

      const finalRotation = calculateRotation(prizeItem);

      setRotation(finalRotation);

      setTimeout(() => {
        setPrize(prizeItem);
        setRotation(INITIAL_ROTATION);
        invalidateFortunaBalance();
        query.invalidateQueries(USER_BALANCE_ANALYTIC_MODULE_NAME);
        setIsSpinning(false);
      }, 8000);
    } catch (error) {
      setIsSpinning(false);
    }
  };

  return (
    <Component
      onSpin={handleSpin}
      balance={fortunaBalance}
      isSpinning={isSpinning}
      rotation={rotation}
      prize={prize}
      onCloseModal={() => setPrize(null)}
    />
  );
};
