import { FORTUNA_PRIZE_ENUM } from '../../data/fortuna/constant';

export const MODULE_NAME = 'FORTUNA_LIST_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: (params: string) => `/fortuna/user-list/${params}`,
};

export interface FORTUNA_ITEM_DATA_RAW_INTER {
  id: string;
  prize: FORTUNA_PRIZE_ENUM;
  createDate: string;
}

export interface FORTUNA_ITEM_LIST_DATA_RAW_INTER {
  list: FORTUNA_ITEM_DATA_RAW_INTER[];
  page?: number;
  pageSize?: number;
  total?: number;
}
