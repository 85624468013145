import i18next from 'i18next';
import { TOP_USER_BY } from '../../data/user/constant';


export const getLocalizedAmount = (amount: number, type: TOP_USER_BY) => {
  const amountKey = amount.toString();

  if (type === TOP_USER_BY.INVITED_AMOUNT) {
    if (i18next.exists(`USER.RATING.INVITED.${amountKey}`)) {
      return i18next.t(`USER.RATING.INVITED.${amountKey}`, { count: amount });
    }
    return i18next.t('USER.RATING.INVITED.DEFAULT', { count: amount });
  }

  if (type === TOP_USER_BY.SELLS_AMOUNT) {
    if (i18next.exists(`USER.RATING.SELL.${amountKey}`)) {
      return i18next.t(`USER.RATING.SELL.${amountKey}`, { count: amount });
    }
    return i18next.t('USER.RATING.SELL.DEFAULT', { count: amount });
  }

  if (type === TOP_USER_BY.SELLS_SUM) {
    return `$ ${amount}`;
  }

  return amount;
};