import { USER_ROLE } from '../../data/auth/constant';
import { ADDITIONAL_PAGE_PATH } from '../../page/additional';
import { EARNING_ADMIN_PAGE_PATH } from '../../page/admin-earning';
import { EVENT_ADMIN_PAGE_PATH } from '../../page/admin-event';
import { FORTUNA_ADMIN_PAGE_PATH } from '../../page/admin-fortuna';
import { USER_ADMIN_PAGE_PATH } from '../../page/admin-user';
import { WITHDRAW_PAGE_PATH } from '../../page/admin-withdraw';

export const HEADER_MENU_ADMIN_LIST = [
  {
    img: './assets/menu/receipt.svg',
    imgActive: './assets/menu/receipt-active.svg',
    tid: 'HEADER.MENU.RECEIPT',
    link: EARNING_ADMIN_PAGE_PATH,
  },

  {
    img: './assets/menu/calendar.svg',
    imgActive: './assets/menu/calendar-active.svg',
    tid: 'HEADER.MENU.EVENTS_ADMIN',
    link: EVENT_ADMIN_PAGE_PATH,
  },

  {
    img: './assets/menu/people.svg',
    imgActive: './assets/menu/people-active.svg',
    tid: 'HEADER.MENU.USERS',
    link: USER_ADMIN_PAGE_PATH,
  },

  {
    img: './assets/menu/requests.svg',
    imgActive: './assets/menu/requests-active.svg',
    tid: 'HEADER.MENU.REQUEST',
    link: WITHDRAW_PAGE_PATH,
  },

  {
    img: './assets/menu/fortuna.svg',
    imgActive: './assets/menu/fortuna-active.svg',
    tid: 'HEADER.MENU.WHEEL',
    link: FORTUNA_ADMIN_PAGE_PATH,
  },

  {
    img: './assets/menu/additional.svg',
    imgActive: './assets/menu/additional-active.svg',
    tid: 'HEADER.MENU.ADDITIONAL',
    link: ADDITIONAL_PAGE_PATH,
  },
];

export const getMenuList = (role?: USER_ROLE) => {
  switch (role) {
    case USER_ROLE.ADMIN:
      return HEADER_MENU_ADMIN_LIST;

    default:
      return HEADER_MENU_ADMIN_LIST;
  }
};
