import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { TextElem } from '../../../common/text';
import { Spacing } from '../../../theme';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../../theme/value';
import { USER_EARNING_ITEM_DATA_INTER } from '../constant';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { USER_EARNING_STATUS } from '../../earning-admin-list/constant';
import { COLOR_ENUM } from '../../../theme/color';
import CloseSvg from '../../../asset/svg/close.svg';
import { TOP_USER_BY } from '../../../data/user/constant';
import { getLocalizedAttempts } from '../../../lib/util/getLocalizedAttempts';
import { getLocalizedAmount } from '../../../lib/util/getLocalizedAmount';

export const CardEarning: React.FC<{ data: USER_EARNING_ITEM_DATA_INTER }> = ({
  data,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div>
      <Container onClick={openModal}>
        <BalanceContainer>
          <TextElem
            color="white"
            size="semiSmall"
            type="semi-bold"
            tid={`$ ${data.amount}`}
          />
          <TextElem
            color="textSecondary"
            size="small"
            type="medium"
            tid="EARNING.LIST.DATE"
            tvalue={{ date: data.createDate }}
          />
        </BalanceContainer>

        <RoleContainer>
          <Chip status={data.statusDefault}>
            <TextElem
              color="white"
              size="small"
              type="medium"
              tid={data.status}
            />
          </Chip>
        </RoleContainer>
      </Container>

      {isModalOpen && (
        <ModalBackdrop onClick={closeModal}>
          <ModalContainer onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={closeModal}>
              <img src={CloseSvg} />
            </CloseButton>
            <ModalContent>
              <TextElem
                color="toggleHandle"
                size="medium"
                type="medium"
                tid="EARNING.USER.MODAL.EARNING"
              />
              <BalanceModal>
                <BalanceContainer>
                  <TextElem
                    color="white"
                    size="semiSmall"
                    type="semi-bold"
                    tid={`$ ${data.amount}`}
                  />
                  <TextElem
                    color="textSecondary"
                    size="small"
                    type="medium"
                    tid="EARNING.LIST.DATE"
                    tvalue={{ date: data.createDate }}
                  />
                </BalanceContainer>

                <RoleContainer>
                  <Chip status={data.statusDefault}>
                    <TextElem
                      color="white"
                      size="small"
                      type="medium"
                      tid={data.status}
                    />
                  </Chip>
                </RoleContainer>
              </BalanceModal>
              <InfoContainer>
                <TextElem
                  size="small"
                  type="medium"
                  color="textSecondary"
                  tid="EARNING.USER.MODAL.SOURCE"
                />
                <TextElem
                  tid={data?.event?.title}
                  type="semi-bold"
                  color="textThird"
                  size="alert"
                />
              </InfoContainer>
              <InfoContainer>
                <TextElem
                  tid="EARNING.USER.MODAL.COMMENT"
                  size="small"
                  type="medium"
                  color="textSecondary"
                />
                {data?.comment && (
                  <TextElem
                    tid={data.comment}
                    type="semi-bold"
                    color="textThird"
                    size="alert"
                  />
                )}
                {!data?.comment && (
                  <TextElem
                    tid="EARNING.USER.MODAL.COMMENT_NOT_SET"
                    type="semi-bold"
                    color="textThird"
                    size="alert"
                  />
                )}
              </InfoContainer>
              {data?.isFortuna === true && data?.fortunaAmount && (
                <InfoContainer>
                  <TextElem
                    tid="EARNING.USER.MODAL.IS_FORTUNA.TITLE"
                    size="small"
                    type="medium"
                    color="textSecondary"
                  />

                  <TextElem
                    tid={`+ ${getLocalizedAttempts(data?.fortunaAmount)}`}
                    type="semi-bold"
                    color="textThird"
                    size="alert"
                  />
                </InfoContainer>
              )}

              {data?.isInvited === true && data?.invitedAmount && (
                <InfoContainer>
                  <TextElem
                    tid="EARNING.USER.MODAL.IS_INVITED.TITLE"
                    size="small"
                    type="medium"
                    color="textSecondary"
                  />

                  <TextElem
                    tid={getLocalizedAmount(
                      data?.invitedAmount,
                      TOP_USER_BY.INVITED_AMOUNT,
                    )}
                    type="semi-bold"
                    color="textThird"
                    size="alert"
                  />
                </InfoContainer>
              )}

              {data?.isPerson === true && data?.personAmount && (
                <InfoContainer>
                  <TextElem
                    tid="EARNING.USER.MODAL.IS_PERSON.TITLE"
                    size="small"
                    type="medium"
                    color="textSecondary"
                  />

                  <TextElem
                    tid={getLocalizedAmount(
                      data?.personAmount,
                      TOP_USER_BY.INVITED_AMOUNT,
                    )}
                    type="semi-bold"
                    color="textThird"
                    size="alert"
                  />
                </InfoContainer>
              )}
            </ModalContent>
          </ModalContainer>
        </ModalBackdrop>
      )}
    </div>
  );
};

const Chip = styled.div<{ status: USER_EARNING_STATUS }>`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.TAG]}px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;

  ${({ status }) => {
    if (status === USER_EARNING_STATUS.PENDING) {
      return css`
        background-color: rgba(69, 172, 229, 0.3);

        span {
          color: #45ace5;
        }
      `;
    }

    if (status === USER_EARNING_STATUS.COMPLETE) {
      return css`
        background-color: rgba(54, 178, 134, 0.3);

        span {
          color: #36b286;
        }
      `;
    }

    if (status === USER_EARNING_STATUS.CANCEL) {
      return css`
        background-color: rgba(242, 72, 72, 0.3);

        span {
          color: #f24848;
        }
      `;
    }
  }}
`;

const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
  gap: 6px;
`;

const RoleContainer = styled.div`
  display: grid;
  justify-self: end;
  min-width: 82px;
  text-align: end;
  align-self: center;
`;

const RoleButtonContainer = styled.div`
  display: grid;
  justify-self: end;

  position: absolute;
  right: 8px;
  max-width: 95px;
  top: 50%;
  transform: translateY(-50%);
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  transition: all ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]},
    height 0.3s ease;

  &:hover {
    > *:not(${RoleButtonContainer}) {
      opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
    }
  }
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 100;
`;

const ModalContainer = styled.div`
  width: 100%;
  height: 85%;
  background: white;
  border-top-left-radius: ${Spacing(4)};
  border-top-right-radius: ${Spacing(4)};
  padding: ${Spacing(6)} ${Spacing(5)} ${Spacing(12)} ${Spacing(5)};
  overflow-y: auto;
  animation: slideUp 0.3s ease-out;
  position: relative;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};

  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 201;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const BalanceModal = styled.div`
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  display: flex;
  justify-content: space-between;
  padding: ${Spacing(4)};
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
`;
