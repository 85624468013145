import React, { ReactNode, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Spacing } from '../../theme';
import { TextElem } from '../text';
import { COLOR_ENUM } from '../../theme/color';
import {
  VALUE_OPACITY_DATA,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import { useLocation } from 'react-router';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';

export const Elem: React.FC<{
  children?: ReactNode;
  title: string;
}> = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    handleClose();
  }, [location.pathname]);

  return (
    <>
      <ButtonStyled onClick={handleClick}>
        <img src="./assets/menu/menu-open.svg" alt="Menu" />
      </ButtonStyled>
      <ModalOverlay isOpen={isOpen} onClick={handleClose}>
        <Menu onClick={(e) => e.stopPropagation()} isOpen={isOpen}>
          <Header>
            <TextElem
              color="textPrimary"
              type="semi-bold"
              tid={title}
              size="title"
              style={{ margin: '0px' }}
            />
            <ButtonStyled onClick={handleClose}>
              <img
                src="./assets/menu/close.svg"
                alt="Close"
                style={{ height: '24px', width: '24px' }}
              />
            </ButtonStyled>
          </Header>
          <Divider />
          {children}
        </Menu>
      </ModalOverlay>
    </>
  );
};

const ButtonStyled = styled.button`
  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  }

  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  padding: 0;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DIVIDER]};
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${Spacing(6)};
`;

const ModalOverlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(20, 18, 26, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: ${Spacing(5)};
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.5s, opacity 0.5s ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      visibility: visible;
      opacity: 1;
      transition: visible 0.5;
    `}
`;

const Menu = styled.div<{ isOpen: boolean }>`
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? '0' : '-300px')};
  max-width: 65%;
  height: 100vh;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  display: flex;
  gap: ${Spacing(7)};
  flex-direction: column;
  width: 100%;
  padding: ${Spacing(16)} 0;
  transition: left 0.3s ease-in-out;
  border-right: 1px solid ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};
  border-top-right-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.CARD
  ]}px;
  z-index: 1000;
`;
