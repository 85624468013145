import React, { useMemo, useState } from 'react';

import { FORTUNA_ITEM_LIST_DATA_RAW_INTER, MODULE_NAME } from './constant';
import { Component } from './component';
import { action } from './action';
import { ACTION_ERROR_INTER } from '../event-list/constant';
import { usePaginatedData } from '../../lib/util/usePagintationData';
import { convert } from './convert';

export const Container: React.FC = () => {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const pageSize = 10;

  const onSuccess = (data: any) => {
    const totalPage = data?.pages[0].total;

    if (totalPage) {
      setPageCount(totalPage);
    }
  };

  const {
    data: preFetch,
    isLoading,
    isError,
    isSuccess,
    error,
  } = usePaginatedData(
    action,
    MODULE_NAME,
    {
      pageParam: page,
      pageSize,
    },
    onSuccess,
  );

  const countData = preFetch?.pages[0].count;

  const list = useMemo(() => {
    const data = preFetch?.pages[0] as FORTUNA_ITEM_LIST_DATA_RAW_INTER;

    if (data) {
      return convert(data.list);
    }

    return [];
  }, [preFetch]);

  const getErrorMessage = () => {
    const errorObj: ACTION_ERROR_INTER = error as ACTION_ERROR_INTER;

    if (errorObj) {
      return errorObj.message;
    }
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  return (
    <Component
      pageCount={pageCount}
      pageSize={pageSize}
      page={page}
      onChangePage={handlePageChange}
      count={countData}
      list={list}
      isLoading={isLoading}
      isError={isError}
      isSuccess={isSuccess}
      errorMessage={getErrorMessage()}
    />
  );
};
