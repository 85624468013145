import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'EARNING_LIST_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  AMOUNT = 'amount',
  STATUS = 'status',
  EVENT = 'event',
  COMMENT = 'comment',
  PERSON_DATA = 'personData',
  IS_PERSON = 'isPerson',
  PERSON_AMOUNT = 'personAmount',

  IS_INVITED = 'isInvited',
  INVITED_AMOUNT = 'invitedAmount',

  IS_FORTUNA = 'isFortuna',
  FORTUNA_AMOUNT = 'fortunaAmount',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.AMOUNT]: number;
  [FORM_VALUE_ENUM.STATUS]: string;
  [FORM_VALUE_ENUM.EVENT]: string;
  [FORM_VALUE_ENUM.COMMENT]: string;
}

export const API = {
  TYPE: 'GET',
  URL: (params: string) => `earning/admin-earning-list/${params}`,
};

// export interface ACTION_RESPONSE_INTER
//   extends EARNING_ITEM_LIST_DATA_RAW_INTER {}

// export interface DATA_INTER extends EARNING_ITEM_LIST_DATA_INTER {}

export enum USER_EARNING_STATUS {
  PENDING = 'Pending',
  COMPLETE = 'Complete',
  CANCEL = 'Cancel',
}

export const EARNING_STATUS_VIEW = {
  [USER_EARNING_STATUS.PENDING]: i18n.t('EARNING.STATUS.PENDING'),
  [USER_EARNING_STATUS.COMPLETE]: i18n.t('EARNING.STATUS.COMPLETE'),
  [USER_EARNING_STATUS.CANCEL]: i18n.t('EARNING.STATUS.CANCEL'),
};

export interface EARNING_ITEM_DATA_RAW_INTER {
  id: string;
  amount: string;
  status: USER_EARNING_STATUS;
  comment: string;
  createDate: string;
  isPerson: boolean;
  personAmount: number;

  isInvited: boolean;
  invitedAmount: number;

  isFortuna: boolean;
  fortunaAmount: number;
  personData: string;
  user: {
    id: string;
    telegramId: string;
    userName: string;
    firstName: string;
    lastName: string;
    balance: string;
  };
  event: {
    title: string;
    id: string;
  };
}

export interface EARNING_ITEM_LIST_DATA_RAW_INTER {
  list: EARNING_ITEM_DATA_RAW_INTER[];
}

export interface EARNING_ITEM_DATA_INTER
  extends Omit<EARNING_ITEM_DATA_RAW_INTER, 'event' | 'status'> {
  event: {
    label: string;
    value: string;
  };
  status: {
    label: string;
    value: string;
  };
}

export interface EARNING_ITEM_LIST_DATA_INTER {
  list?: EARNING_ITEM_DATA_INTER[];
  isEmpty: boolean;
}

export interface DATA_INTER extends EARNING_ITEM_LIST_DATA_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export interface ACTION_RESPONSE_INTER
  extends EARNING_ITEM_LIST_DATA_RAW_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export const STATUS_OPTION_LIST = [
  {
    value: USER_EARNING_STATUS.COMPLETE,
    label: i18n.t('EARNING.STATUS.COMPLETE'),
  },

  {
    value: USER_EARNING_STATUS.CANCEL,
    label: i18n.t('EARNING.STATUS.CANCEL'),
  },

  {
    value: USER_EARNING_STATUS.PENDING,
    label: i18n.t('EARNING.STATUS.PENDING'),
  },
];
